import React, {useEffect} from 'react';
import SectionTitle from "../../../components/SectionTitle";
import {
    AppContainer,
    ButtonContainer,
    CurrentNewsImageWrapper,
    CurrentNewsTextWrapper, IframeContainer,
    InnerContainer,
    NewsImageStyled,
    ShareIcon,
    ShareIconContainer,
    TextContainer,
    NewsContentContainer
} from "./styles";
import {withRouter} from "react-router";
import Loading from "../../../components/Loading";
import iconShare from "../../../assets/images/iconCompartilhar.svg";
import RoundButton from "../../../components/RoundButton";
import {useFindOneNewsHandlers, useNews, useNewsLoading} from "../../../store/hooks/news";
import {Link} from "react-router-dom";

type Props = {
    history: any,
    location: any,
    match: {
        params: {
            id: string
        }
    }
}

const NewsDetailPage = ({match, history}: Props) => {
    const loading = useNewsLoading();
    const currentNews = useNews();
    const findOneNewsRequest = useFindOneNewsHandlers();

    const {id: idParam} = match.params;

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

    useEffect(() => {
        findOneNewsRequest(parseInt(idParam));
        window.scrollTo(0, 1);
    }, [findOneNewsRequest, idParam]);

    return (
        <AppContainer>
            { loading && <Loading/> }
            <InnerContainer>
                <SectionTitle title="Notícias"/>
            </InnerContainer>
                <NewsContentContainer>
                    {
                        currentNews?.display_video ?
                            <IframeContainer dangerouslySetInnerHTML={currentNews?.embedVideo}/> :
                        <NewsImageStyled src={`data:image/png;base64,${currentNews?.image_preview}`}/>
                    }
                    <TextContainer>
                        <h4>{currentNews?.title}</h4>
                        <h5>{currentNews?.author}</h5>
                    </TextContainer>
                    <div dangerouslySetInnerHTML={currentNews?.content}/>
                </NewsContentContainer>
                <ButtonContainer>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=localhost:3000/#/news/${currentNews?.id}`}>
                        <ShareIconContainer>
                            <ShareIcon src={iconShare}/>
                        </ShareIconContainer>
                    </a>
                    <Link to={"/news"}>
                        <RoundButton
                            variant="primary"
                            size="sm"
                            type="submit">
                            VOLTAR PARA TODAS AS NOTÍCIAS
                        </RoundButton>
                    </Link>
                </ButtonContainer>
        </AppContainer>
    );
};

export default withRouter(NewsDetailPage);
