import {News} from '../../interfaces/News';

export const NEWS_FIND_ALL_REQUEST = 'NEWS_FIND_ALL_REQUEST';
export const NEWS_FIND_ALL_SUCCESS = 'NEWS_FIND_ALL_SUCCESS';
export const NEWS_FIND_ONE_REQUEST = 'NEWS_FIND_ONE_REQUEST';
export const NEWS_FIND_ONE_SUCCESS = 'NEWS_FIND_ONE_SUCCESS';
export const NEWS_FIND_ERROR = 'NEWS_FIND_ERROR';


export interface NewsFindAllRequestAction {
    type: typeof NEWS_FIND_ALL_REQUEST,
    payload: {
        page: number,
        size: number
    }
}

export interface NewsFindAllSuccessAction {
    type: typeof NEWS_FIND_ALL_SUCCESS,
    payload: {
        newsList: News[]
        total: number
    }
}

export interface NewsFindOneRequestAction {
    type: typeof NEWS_FIND_ONE_REQUEST,
    payload: {
        id: number
    }
}

export interface NewsFindOneSuccessAction {
    type: typeof NEWS_FIND_ONE_SUCCESS,
    payload: {
        news: News
    }
}

export interface NewsFindAllErrorAction {
    type: typeof NEWS_FIND_ERROR,
}

export const newsFindAllRequestAction = (page: number, size: number): NewsFindAllRequestAction => ({
    type: NEWS_FIND_ALL_REQUEST,
    payload: {
        page,
        size
    }
});

export const newsFindAllSuccessAction = (newsList: News[], total: number): NewsFindAllSuccessAction => ({
    type: NEWS_FIND_ALL_SUCCESS,
    payload: {
        newsList,
        total
    }
});

export const newsFindOneRequestAction = (id: number): NewsFindOneRequestAction => ({
    type: NEWS_FIND_ONE_REQUEST,
    payload: {
        id
    }
});

export const newsFindOneSuccessAction = (news: News): NewsFindOneSuccessAction => ({
    type: NEWS_FIND_ONE_SUCCESS,
    payload: {
        news
    }
});

export const newsFindAllErrorAction = (): NewsFindAllErrorAction => ({
    type: NEWS_FIND_ERROR,
});

export type Action =
    NewsFindAllRequestAction |
    NewsFindAllErrorAction |
    NewsFindOneRequestAction |
    NewsFindOneSuccessAction |
    NewsFindAllSuccessAction;

export interface State {
    loading: boolean,
    successFindAll: boolean,
    newsList: News[],
    news: News | null,
    total: number
}

const INITIAL_STATE = {
    loading: false,
    successFindAll: false,
    newsList: [],
    news: null,
    total: 0
};

const newsReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case NEWS_FIND_ALL_REQUEST: {
            return {
                ...state,
                loading: true,
                successFindAll: false
            }
        }
        case NEWS_FIND_ALL_SUCCESS: {
            const {newsList, total} = action.payload;
            return {
                ...state,
                newsList,
                total,
                loading: false,
                successFindAll: true,
            }
        }
        case NEWS_FIND_ONE_REQUEST: {
            return {
                ...state,
                loading: true,
                successFindAll: false
            }
        }
        case NEWS_FIND_ONE_SUCCESS: {
            const {news} = action.payload;
            return {
                ...state,
                news,
                loading: false,
                successFindAll: true,
            }
        }
        case NEWS_FIND_ERROR: {
            return {
                ...state,
                loading: false,
                successFindAll: false,
            }
        }
        default:
            return state;
    }
};

export default newsReducer;
