import {Testimonial} from "../../interfaces/Testimonial";

export const TESTIMONIAL_REQUEST = 'TESTIMONIAL_REQUEST';
export const TESTIMONIAL_SUCCESS = 'TESTIMONIAL_SUCCESS';


export interface TestimonialRequestAction {
    type: typeof TESTIMONIAL_REQUEST
}

export interface TestimonialSuccessAction {
    type: typeof TESTIMONIAL_SUCCESS,
    payload: {
        testimonialList: Testimonial[]
    }
}

export const testimonialRequestAction = (): TestimonialRequestAction => ({
    type: TESTIMONIAL_REQUEST
});

export const testimonialSuccessAction = (testimonialList: Testimonial[]): TestimonialSuccessAction => ({
    type: TESTIMONIAL_SUCCESS,
    payload: {
        testimonialList
    }
});

export type Action =
    TestimonialRequestAction |
    TestimonialSuccessAction;

export interface State {
    loading: boolean,
    testimonialList: Testimonial[],
    success: boolean,
}

const INITIAL_STATE = {
    loading: false,
    testimonialList: [],
    success: false,
};

const testimonialReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case TESTIMONIAL_REQUEST: {
            return {
                ...state,
                loading: true,
                success: false
            }
        }
        case TESTIMONIAL_SUCCESS: {
            const {testimonialList} = action.payload;
            return {
                ...state,
                loading: false,
                testimonialList,
                success: true,
            }
        }
        default:
            return state;
    }
};

export default testimonialReducer;
