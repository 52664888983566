import styled from 'styled-components';
import {Link} from "react-router-dom";
import {DatePicker} from "antd";

export const AppContainer = styled.div`
    text-align: center;
    margin: 4rem 0 3rem;
    display: flex;
    justify-content: center;
`;

export const InnerContainer = styled.div`
    width: 40%;
    @media (max-width: 650px) {
        width: unset;
    }
`;

export const LinkStyled = styled(Link)`
    color: #7e778b;
    :hover {
        color: #7e778b;
    }
`;

export const DatePickerStyled = styled(DatePicker)`
    width: 100%;
    .ant-calendar-picker-input.ant-input {
        border-radius: 25px;
        height: 35px;
        text-align: center;
    }
`;



