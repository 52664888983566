import React from 'react';
import ImageSlider from "../../components/ImageSlider";
import CompanySection from "../../components/Sections/CompanySection";
import ProcedureSection from "../../components/Sections/ProcedureSection";
import ProfessionalSection from "../../components/Sections/ProfessionalSection";
import TestimonialSection from "../../components/Sections/TestimonialSection";
import RegistrationSection from "../../components/Sections/RegistrationSection";
import styled from "styled-components";
import silicredLogo from "../../assets/images/silicred-logo.png";
import RoundButton from "../../components/RoundButton";
import {CenteredContentContainer, FooterSilicredContainer} from "./styles";
import SectionTitle from "../../components/SectionTitle";
import Reveal from 'react-reveal/Reveal';
import NewsSection from "../../components/Sections/NewsSection";

export let Professionals: any;
export let Procedures: any;
export let News: any;
export let Company: any;
export let Login: any;

export const AppContainer = styled.div`
    text-align: center
`;

const HomePage = () => {

    return (
        <AppContainer>
            <ImageSlider/>
            <SectionTitle title="A Empresa" />
            <section ref={(section) => { Company = section; }}>
                <Reveal effect="fadeInUp">
                    <CompanySection/>
                </Reveal>
            </section>
            <SectionTitle title="Notícias" />
            <section ref={(section) => { News = section; }}>
                <Reveal effect="fadeInUp">
                    <NewsSection/>
                </Reveal>
            </section>
            <SectionTitle title="Procedimentos" />
            <section ref={(section) => { Procedures = section; }}>
                <Reveal effect="fadeInUp">
                    <ProcedureSection/>
                </Reveal>
            </section>
            <SectionTitle title="Profissionais" />
            <section ref={(section) => { Professionals = section; }}>
                <Reveal effect="fadeInUp">
                    <ProfessionalSection/>
                </Reveal>
            </section>
            <SectionTitle title="Depoimentos" />
            <Reveal effect="fadeInUp">
                <TestimonialSection/>
            </Reveal>
            <SectionTitle title="Entrar" />
            <section ref={(section) => { Login = section; }}>
                <Reveal effect="fadeInUp">
                    <RegistrationSection/>
                </Reveal>
            </section>
            <FooterSilicredContainer>
                <div>
                    <a href="https://silicred.com.br/" target="noreferrer">
                        <img src={silicredLogo} style={{maxWidth: '150px'}} alt=""/>
                    </a>
                </div>
                <CenteredContentContainer>
                    <h3 style={{fontSize: '18px', maxWidth: '400px', color: '#6C5876'}}>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                            A SILICRED - nossa parceira em soluções financeiras - viabiliza seu sonho com parcelas que cabem no seu bolso!
                        </p>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                            Sem consulta ao SPC!
                        </p>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                            São cinco soluções de pagamento! Clique agora em "saiba mais" e veja a mais interessante para você!
                        </p>
                    </h3>
                </CenteredContentContainer>
                <CenteredContentContainer>
                    <a href="https://silicred.com.br/" target="noreferrer">
                        <RoundButton variant="primary" size="sm">
                            Saiba mais
                        </RoundButton>
                    </a>
                </CenteredContentContainer>
            </FooterSilicredContainer>
        </AppContainer>
    );
};

export default HomePage;
