import styled from 'styled-components';

export const SectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column; 
    padding: 5rem 1rem;
    @media (max-width: 1150px) {
        padding: 2rem 1rem;
    }
`;
