import { all } from 'redux-saga/effects';
import authEffects from './authentication';
import addressEffects from './address';
import procedureEffects from './procedure';
import testimonialEffects from './testimonial';
import professionalEffects from './professional';
import leadEffects from './lead';
import newsEffects from './news';
import appointmentEffects from './appointment';


export default function* rootSaga() {
  return yield all([
      ...authEffects,
      ...addressEffects,
      ...procedureEffects,
      ...professionalEffects,
      ...leadEffects,
      ...newsEffects,
      ...testimonialEffects,
      ...appointmentEffects
  ]);
}
