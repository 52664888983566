import React, {useState} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useAuthenticated, useShowLoginHandler} from '../../store/hooks/authentication';
import {showErrorToast} from "../../services/toast";

interface PrivateRouteProps {
    hasAnyAuthorities?: string[]
}

interface PrivateRoute extends RouteProps, PrivateRouteProps {
}

const PrivateRoute = ({component, path, exact, hasAnyAuthorities}: PrivateRoute) => {
    const authenticated = useAuthenticated();
    const showLogin = useShowLoginHandler();
    const [loading, setLoading] = useState(true);

    setTimeout( () => {
            setLoading(false);
    }, 500);

    if (loading) {
        return <span style={{ flex: 1}}/>;
    }

    if (!authenticated) {
        showErrorToast('Por favor faça login para acessar esta página.');
        showLogin();
        return <Redirect to="/home"/>
    }

    return (
        <Route component={component} path={path} exact={exact}/>
    );
};

export default PrivateRoute;
