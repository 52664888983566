import React, {useCallback, useEffect} from 'react';
import SectionTitle from "../../components/SectionTitle";
import ValidationSchema from "./ValidationSchema";
import Row from "../../components/Row";
import FormInput from "../../components/FormInput";
import {ErrorContainer, ErrorLabel} from "../../elements/Error";
import {ErrorMessage, Formik} from "formik";
import RoundButton from "../../components/RoundButton";
import {AppContainer, DatePickerStyled, InnerContainer} from "./styles";
import {withRouter} from "react-router";
import SuccessSentData from "./SuccessSentData";
import {
    useAppointmentData,
    useClearAppointmentDataSuccess,
    useLoadAppointmentData,
    useSendAppointmentDataRequestHandler
} from "../../store/hooks/appointment";
import {Appointment} from "../../interfaces/Appointment";
import {TextArea} from '../../components/TextArea';

type Props = {
    history: any,
    location: any,
    match: {
        params: {
            hash: string
        }
    }
}

let initialValues: Appointment = {
    procedure: '',
    value: 0,
    observation: ''
};

const AppointmentDataPage = (props: Props) => {
    const {hash: qrCodeHash} = props.match.params;
    const sendAppointmentData = useSendAppointmentDataRequestHandler();
    const loadAppointmentDataAction = useLoadAppointmentData();
    const clearAppointmentDataSuccess = useClearAppointmentDataSuccess();
    const appointmentData = useAppointmentData();
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );

    const handleSubmitAppointmentData = useCallback((values: any) => {
        sendAppointmentData({...values, hash: qrCodeHash, procedureDate: selectedDate});
    }, [sendAppointmentData, selectedDate, qrCodeHash]);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    useEffect( () => {
        loadAppointmentDataAction(qrCodeHash);
    }, [loadAppointmentDataAction, qrCodeHash]);

    useEffect( () => {
        clearAppointmentDataSuccess();
        return () => clearAppointmentDataSuccess();
    }, [clearAppointmentDataSuccess]);

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

    return (
        <AppContainer>
            <InnerContainer>
                <SectionTitle title="Informações da Consulta" />
                { appointmentData &&
                    <>
                        <h5>Médico: {appointmentData?.professional?.name}</h5>
                        <h5>Paciente: {appointmentData?.user?.name}</h5>
                    </>
                }
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitAppointmentData}
                    validationSchema={ValidationSchema}
                    enableReinitialize={true}>
                    {({handleSubmit, values, handleChange, setFieldValue}) => (
                        <>
                            <Row modifiers="small"/>
                            <FormInput type="text" placeholder="procedimento a ser realizado" name="procedure" value={values.procedure} onChange={handleChange} maxLength={255}/>
                            <FormInput type="number" placeholder="valor do procedimento" name="value" value={values.value} onChange={handleChange} maxLength={255}/>
                            <DatePickerStyled placeholder="data prevista" onChange={(data: any) => handleDateChange(data)} />
                            <TextArea name="observation" placeholder="Valor pago pelo procedimento R$
Valor do desconto recebido R$" value={values.observation} onChange={(event: any) => setFieldValue('observation', event.target.value)}/>

                            <ErrorContainer>
                                <ErrorMessage name="procedure" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                <ErrorMessage name="value" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                            </ErrorContainer>
                            <SuccessSentData/>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                    CONFIRMAR
                                </RoundButton>
                            </div>
                        </>
                    )}
                </Formik>
            </InnerContainer>
        </AppContainer>
    );
};

export default withRouter(AppointmentDataPage);
