import React, { useEffect } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ProfessionalsSearch from "./pages/ProfessionalsSearch";
import TopMenu from "./components/TopMenu";
import HomePage from "./pages/HomePage";
import FooterContainer from "./components/FooterContainer";
import ProceduresPage from "./pages/Procedures";
import Dialog from "./elements/Dialog";
import LoginForm from "./components/LoginForm";
import {
    useAuthenticated,
    useAuthIncomplete, useCancelIncompleteAuth,
    useHideLoginHandler,
    useIsLoginVisible
} from "./store/hooks/authentication";
import {useClearQrCode, useGeneratedQrCode} from "./store/hooks/professional";
import QrCodeDialogContent from "./components/QrCodeDialogContent";
import PrivateRoute from "./components/PrivateRoute";
import ResetPage from "./pages/ResetPage";
import AppointmentDataPage from "./pages/AppointmentDataPage";
import CompleteDataDialog from "./components/CompleteDataDialog";
import NewsPage from "./pages/NewsPage";
import NewsDetailPage from "./pages/NewsPage/NewsDetailPage";
import WhatsAppShortcut from "./components/WhatsAppShortcut";
import { LoginPage } from "./pages/LoginPage";

export let Home: any;

const Routes: React.FC = () => {
    const loginVisible = useIsLoginVisible();
    const hideLogin = useHideLoginHandler();
    const cancelIncompleteAuth = useCancelIncompleteAuth();
    const authenticated = useAuthenticated();
    const authIncomplete = useAuthIncomplete();
    const generatedQrCode = useGeneratedQrCode();
    const clearQrCode = useClearQrCode();

    const hideLoginDialog = () => {
        hideLogin();
    };

    return (
        <>
            <section ref={(section) => { Home = section; }}>
                <TopMenu/>
            </section>
            <WhatsAppShortcut/>
            { loginVisible && !authenticated &&
            <Dialog setHidden={hideLoginDialog} width="30">
                <LoginForm/>
            </Dialog>
            }
            {
                generatedQrCode &&
                <Dialog setHidden={clearQrCode}>
                    <QrCodeDialogContent qrCode={generatedQrCode}/>
                </Dialog>
            }
            {
                authIncomplete &&
                <Dialog setHidden={cancelIncompleteAuth}>
                    <CompleteDataDialog/>
                </Dialog>
            }
            <Switch>
                <Route exact path={`/login`} component={LoginPage}/>
                <Route exact path={`/procedure/:id/:type`} component={ProceduresPage}/>
                <Route exact path={`/reset-password/:hash`} component={ResetPage}/>
                <Route exact path={`/news`} component={NewsPage}/>
                <Route exact path={`/news/:id`} component={NewsDetailPage}/>
                <Route exact path={`/appointment-data/:hash`} component={AppointmentDataPage}/>
                <Route exact path="/home" component={HomePage}/>
                <PrivateRoute exact path="/search-professionals" component={ProfessionalsSearch}/>
                <Redirect from='*' to='/home' />
            </Switch>
            <FooterContainer />
        </>
    );
};

export default Routes;
