import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    GENERATE_QRCODE_REQUEST, generateQrCodeErrorAction, GenerateQrCodeRequestAction, generateQrCodeSuccessAction,
    PROFESSIONAL_LIST_REQUEST, professionalListErrorAction,
    ProfessionalListRequestAction,
    professionalListSuccessAction
} from "../ducks/professional";
import {showErrorToast} from '../../services/toast';

export function* findProfessionals(action: ProfessionalListRequestAction) {
    try {
        const {cityName} = action.payload;
        const findProfessionalsApiCall = () => {
            return api.get(`/api/professionals/city/${cityName}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const response = yield call(findProfessionalsApiCall);
        yield put(professionalListSuccessAction(response));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
        yield put(professionalListErrorAction());
    }
}

export function* generateQrCode(action: GenerateQrCodeRequestAction) {
    try {
        const {qrCodeData} = action.payload;
        let qrValidated = false;
        const generateQrCodeApiCall = () => {
            return api.get(`/api/qrcode/generate/${qrCodeData.userId}/${qrCodeData.profId}?promotionalCode=${qrCodeData.promotionalCode}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const checkPromoCodeApiCall = () => {
            return api.get(`/api/validate-code/${qrCodeData.promotionalCode}`)
                .then(response => {
                    qrValidated = true;
                    return response.data;
                })
                .catch(err => {
                    showErrorToast(err.data);

                });
        };
        if (qrCodeData.promotionalCode) {
            yield call(checkPromoCodeApiCall);
        } else {
            qrValidated = true;
        }
        if (qrValidated) {
            const response = yield call(generateQrCodeApiCall);
            yield put(generateQrCodeSuccessAction(response));
        } else {
            yield put(generateQrCodeErrorAction());
        }
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
        yield put(generateQrCodeErrorAction());
    }
}

export default [
    takeLatest(PROFESSIONAL_LIST_REQUEST, findProfessionals),
    takeLatest(GENERATE_QRCODE_REQUEST, generateQrCode),
];
