export function getMessage(message: string | undefined) {
    switch (message) {
        case 'Email or Username in use': {
            return 'O e-mail informado ja está em uso.';
        }
        default: {
            return 'Ocorreu um erro ao realizar está ação.';
        }
    }
}
