import { useEffect, useReducer, useRef } from 'react';
import useCompare from './useCompare';

const initialState = {
  isLoading: false,
  filter: {},
  data: null,
  totalItems: 0,
  currentPage: 1,
  pageSize: 5,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        totalItems: action.payload.totalItems,
      };
    case 'FILTER':
      return {
        ...initialState,
        filter: action.payload.filter,
        currentPage: action.payload.currentPage,
        data: action.payload.initialData,
        totalItems: action.payload.totalItems,
      };
    default:
      throw new Error();
  }
};

export default (
  provider: any,
  param: any,
  requestOnMount: any,
  initialData: any,
  errorHandler = (err: any) => {},
  successCallback = (result: any) => {},
  paginate: any
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    data: initialData,
  });
  const diff = useCompare(param);
  const diffProvider = useCompare(provider);
  const shouldLoad = useRef(requestOnMount);

  const { filter, currentPage, pageSize } = state;

  useEffect(() => {
    if (diff || diffProvider) {
      dispatch({ type: 'FETCH_SUCCESS', payload: { data: initialData } });
    } else if (shouldLoad.current) {
      dispatch({ type: 'FETCH_INIT' });
      provider(
        param,
        paginate ? { ...filter, page: currentPage - 1, size: pageSize } : filter
      )
        .then((result: any) => {
          console.log(result.status === 200);
          console.log(result.data);
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              data: result.status === 200 ? result?.data?.data : initialData,
              totalItems: +result?.data?.total
            },
          });
          shouldLoad.current = false;
          successCallback(result);
        })
        .catch(errorHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diff, diffProvider, filter, currentPage, provider, shouldLoad.current]);

  function fetchFilter(payload: any) {
    const { filter: f, page } = payload;
    dispatch({
      type: 'FILTER',
      payload: {
        filter: typeof f === 'string' || typeof f === 'number' ? f : { ...f },
        currentPage: page,
        initialData,
      },
    });
    shouldLoad.current = true;
  }

  return [state, fetchFilter];
};
