import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useChangePasswordSuccess} from "../../store/hooks/authentication";
import Row from "../../components/Row";
import {LinkStyled} from "./styles";

const SuccessChangePassword = () => {
    const successSend = useChangePasswordSuccess();
    const formikContext = useFormikContext();

    useEffect(() => {
        if (successSend) {
            formikContext.resetForm();
        }
    }, [successSend, formikContext]);

    return (
        <>
            {successSend &&
                <Row>
                    <h6>Sua senha foi alterada com sucesso.</h6>
                    <LinkStyled to={"/home"}>Clique aqui para voltar para a página inicial</LinkStyled>
                </Row>
            }
        </>
    )
};

export default SuccessChangePassword;
