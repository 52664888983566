import React from 'react';
import flower from './../../assets/images/flower.png';
import Reveal from "react-reveal/Reveal";
import {SectionTitleContainer} from "./styles";

type Props = {
    title?: string
};

const SectionTitle = (props: Props) => {
    return (
        <SectionTitleContainer>
            <Reveal effect="rollIn">
                <img style={{alignSelf: 'center', width: '50px'}} src={flower} alt=""/>
            </Reveal>
            <h2 style={{textTransform: 'uppercase', letterSpacing: '3px', fontFamily: 'quostige', textAlign: 'center', color: '#6C5876'}}>{props.title}</h2>
        </SectionTitleContainer>
    );
};

export default SectionTitle;
