import {Lead} from '../../interfaces/Lead';
import {Appointment} from "../../interfaces/Appointment";

export const LOAD_APPOINTMENT_DATA = 'LOAD_APPOINTMENT_DATA';
export const CLEAR_APPOINTMENT_SUCCESS = 'CLEAR_APPOINTMENT_SUCCESS';
export const LOAD_APPOINTMENT_DATA_SUCCESS = 'LOAD_APPOINTMENT_DATA_SUCCESS';
export const SEND_APPOINTMENT_DATA_REQUEST = 'SEND_APPOINTMENT_DATA_REQUEST';
export const SEND_APPOINTMENT_DATA_SUCCESS = 'SEND_APPOINTMENT_DATA_SUCCESS';

export interface SendAppointmentDataRequestAction {
    type: typeof SEND_APPOINTMENT_DATA_REQUEST,
    payload: {
        appointment: Appointment
    }
}

export interface SendAppointmentDataSuccessAction {
    type: typeof SEND_APPOINTMENT_DATA_SUCCESS
}

export interface ClearAppointmentSuccessAction {
    type: typeof CLEAR_APPOINTMENT_SUCCESS
}

export interface LoadAppointmentDataAction {
    type: typeof LOAD_APPOINTMENT_DATA,
    payload: {
        hash: string
    }
}

export interface LoadAppointmentDataSuccessAction {
    type: typeof LOAD_APPOINTMENT_DATA_SUCCESS,
    payload: {
        appointmentData: Appointment
    }
}

export const sendAppointmentDataRequestAction = (
    appointment: Appointment
): SendAppointmentDataRequestAction => ({
    type: SEND_APPOINTMENT_DATA_REQUEST,
    payload: {
        appointment
    }
});

export const loadAppointmentDataAction = (
    hash: string,
): LoadAppointmentDataAction => ({
    type: LOAD_APPOINTMENT_DATA,
    payload: {
        hash
    }
});

export const sendAppointmentDataSuccessAction = (): SendAppointmentDataSuccessAction => ({
    type: SEND_APPOINTMENT_DATA_SUCCESS,
});

export const clearAppointmentSuccessAction = (): ClearAppointmentSuccessAction => ({
    type: CLEAR_APPOINTMENT_SUCCESS,
});

export const loadAppointmentDataSuccessAction = (appointmentData: Appointment): LoadAppointmentDataSuccessAction => ({
    type: LOAD_APPOINTMENT_DATA_SUCCESS,
    payload: {
        appointmentData
    }
});


export type Action =
    SendAppointmentDataRequestAction
    | LoadAppointmentDataAction
    | SendAppointmentDataSuccessAction
    | LoadAppointmentDataSuccessAction
    | ClearAppointmentSuccessAction
    ;

export interface State {
    error: any | null,
    loading: boolean,
    user: Lead | null,
    sendAppointmentSuccess: boolean;
    appointmentData: Appointment | null;
}

const INITIAL_STATE = {
    error: null,
    loading: false,
    user: null,
    sendAppointmentSuccess: false,
    appointmentData: null
};


const appointmentReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case SEND_APPOINTMENT_DATA_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SEND_APPOINTMENT_DATA_SUCCESS:
            return {
                ...state,
                sendAppointmentSuccess: true,
                loading: false
            };
        case CLEAR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                sendAppointmentSuccess: false,
                appointmentData: null
            };
        case LOAD_APPOINTMENT_DATA:
            return {
                ...state,
                loading: true
            };
        case LOAD_APPOINTMENT_DATA_SUCCESS:
            const {appointmentData} = action.payload;
            return {
                ...state,
                loading: false,
                appointmentData: appointmentData
            };
        default:
            return state;
    }
};

export default appointmentReducer;
