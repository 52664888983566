import React, { useEffect } from "react";
import { useIsLoginVisible, useShowLoginHandler } from "../../store/hooks/authentication";
import { Redirect } from "react-router-dom";
import HomePage from "../HomePage";

export const LoginPage = () => {
    const showLogin = useShowLoginHandler();
    const loginVisible = useIsLoginVisible();

    useEffect(() => {
        setTimeout(() => {
            showLogin();
        }, 200);
    }, [showLogin])

    if (loginVisible) {
        return <Redirect to="/home"/>
    }

    return <HomePage />
}

export default LoginPage;
