import React from 'react';
import {Container, WhatsAppImage} from './styles';
import wup from "../../assets/images/whatsapp-icon.png";
import {Animated} from 'react-animated-css';
import {Tooltip} from "antd";

const WhatsAppShortcut = (props: any) => {
    return (
        <Container {...props}>
            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                <Tooltip placement="leftTop" title="Clique para falar conosco através do WhatsApp">
                    <a href={`https://api.whatsapp.com/send?phone=554832091999`} target="noreferrer">
                        <WhatsAppImage className="growing" src={wup}/>
                    </a>
                </Tooltip>
            </Animated>
        </Container>
    )
};

export default WhatsAppShortcut;
