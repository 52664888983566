import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {
    authFacebookRequestAction,
    authGoogleRequestAction,
    authLogoutAction,
    authRequestAction, cancelIncompleteAuthAction,
    changePasswordRequestAction,
    clearChangePasswordStateAction,
    clearForgotPasswordStateAction,
    completeUserDataRequestAction,
    forgotPasswordRequestAction,
    hideLoginAction,
    showLoginAction
} from "../ducks/authentication";
import {GlobalState} from "../ducks";
import {ChangePasswordRequest} from "../../interfaces/ChangePasswordRequest";
import {Lead} from "../../interfaces/Lead";

export const useAuthHandlers = () => {
    const dispatch = useDispatch();
    return useCallback(({username, password}) => {
        dispatch(authRequestAction(username, password))
    }, [dispatch]);
};

export const useCompleteUserDataHandler = () => {
    const dispatch = useDispatch();
    return useCallback((user: Lead) => {
        dispatch(completeUserDataRequestAction(user))
    }, [dispatch]);
};

export const useCancelIncompleteAuth = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(cancelIncompleteAuthAction())
    }, [dispatch]);
};

export const useAuthFacebookHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((access_token) => {
        dispatch(authFacebookRequestAction(access_token))
    }, [dispatch]);
};

export const useAuthGoogleHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((access_token) => {
        dispatch(authGoogleRequestAction(access_token))
    }, [dispatch]);
};

export const useForgotPasswordRequest = () => {
    const dispatch = useDispatch();
    return useCallback(({username}) => {
        dispatch(forgotPasswordRequestAction(username))
    }, [dispatch]);
};

export const useChangePasswordRequest = () => {
    const dispatch = useDispatch();
    return useCallback((changePasswordRequest: ChangePasswordRequest) => {
        dispatch(changePasswordRequestAction(changePasswordRequest))
    }, [dispatch]);
};

export const useClearChangePasswordState = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearChangePasswordStateAction())
    }, [dispatch]);
};

export const useClearForgotPasswordState = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearForgotPasswordStateAction())
    }, [dispatch]);
};

export const useChangePasswordSuccess = () => {
    return useSelector((state: GlobalState) => state.auth.changePasswordSuccess);
};

export const useForgotPasswordSuccess = () => {
    return useSelector((state: GlobalState) => state.auth.forgotPasswordSuccess);
};

export const useAccessToken = () => {
    return useSelector((state: GlobalState) => state.auth.token);
};

export const useAuthRequestValues = () => {
    const {username, password} = useSelector((state: GlobalState) => state.auth);
    return {
        username,
        password,
    };
};

export const useIsLoginVisible = () => {
    return useSelector((state: GlobalState) => state.auth.loginPageVisible);
};

export const useShowLoginHandler = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(showLoginAction())
    }, [dispatch]);
};

export const useHideLoginHandler = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(hideLoginAction())
    }, [dispatch]);
};

export const useAuthUser = () => {
    return useSelector((state: GlobalState) => state.auth.user);
};

export const useAuthError = () => {
    return useSelector((state: GlobalState) => state.auth.error);
};

export const useAuthenticated = () => {
    return useSelector((state: GlobalState) => state.auth.authenticated);
};

export const useAuthIncomplete = () => {
    return useSelector((state: GlobalState) => state.auth.authIncomplete);
};

export const useAuthLoading = () => {
    return useSelector((state: GlobalState) => state.auth.loading);
};

export const useLogoutHandler = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(authLogoutAction())
    }, [dispatch]);
};
