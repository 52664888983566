import styled from 'styled-components';
import {Link} from "react-router-dom";

type Props = {
    expanded?: boolean;
    size?: number;
}

export const ImageContainer = styled.div<Props>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    img {
        width: 100%;
    }
    transition: all 0.5s ease;
    @media (max-width: 900px) {
        max-height: ${props => props.expanded ? (props.size ? `${(150 + (props.size * 50))}px` : '350px') : '200px'};
        overflow: hidden;
    }
`;

export const LinkStyled = styled(Link)`
  p {
    text-shadow: 1px 1px 5px gray;
    font-weight: bolder;
    :hover {
      text-decoration: none;
      color: #F1CFC5 !important;
    }
    transition: all 0.4s ease;
  }
  :hover {
    text-decoration: none;
  }
`;

export const ProcedureTitle = styled.h1`
    position: absolute;
    top: 0;
    height: 15%;
    text-shadow: 1px 1px 5px gray;
    font-weight: bolder;
`;

export const ProcedureLayer = styled.div<Props>`
  h1 {
    position: absolute;
    top: 0;
    margin: 0.5rem auto 1rem;
    width: 100%;
    z-index: -1;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  background: rgb(102, 63, 100, 0.5);
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease;

  :hover {
    opacity: 1;
  }

  p {
    font-size: x-large;
  }

  * {
    color: white;
    text-transform: uppercase;
  }

  padding: ${props => props.expanded ? '15%' : '0'};
`;

export const ProcedureLayerMobile = styled.div`
    h1 {
        position: absolute;
        top: 0;
        margin: 0 auto 1rem;
        width: 100%;
        z-index: -1;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    background: rgba(97, 86, 136, 0.5);
    position: absolute;
    transition: opacity 0.5s ease;
    p {
        font-size: x-large;
    }
    * {
        color: white;
        text-transform: uppercase;
    }
`;



export const TitleHiddenOnHoverStyled = styled.div`
    background: rgba(97, 86, 136, 0.3);
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: white;
    }
`;

export const TitleHiddenOnClickStyled = styled.div`
    background: rgba(97, 86, 136, 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: white;
    }
`;

export const FlexContainerProcedures = styled.div`
    max-width: 100%;
    display: flex;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
