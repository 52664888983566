import React from "react";
import {InputStyled} from "./styles";

type Props = {
    handleChange?: any,
    value?: string
}

const FaqSearchField = ({value, handleChange}: Props) => {
    return (
        <InputStyled type="text" placeholder="Procurar..." name="name" value={value} onChange={ (event) => handleChange(event.target.value)} maxLength={255}/>
    );
};

export default FaqSearchField;

