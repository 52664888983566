import styled from "styled-components";

export const NewsContainer = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    padding: 5% 5%;
    color: #6C5876;
    margin: 0 auto;
    @media (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
      padding: 5% 5%;
    }
`;

export const NewsContent = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    text-align: center;
    padding-top: 3%;
    margin-left: 3%;
    @media (max-width: 1150px) {
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 250px;
`;

export const EmptyNewsTitle = styled.h4`
    color: rgba(0,0,0,0.5);
    margin: 7% 0;
`;

export const NewsImageStyled = styled.img`
    max-width: 150px;
    border-radius: 15px;
`;
