import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    PROCEDURE_FIND_REQUEST,
    PROCEDURE_LIST_REQUEST,
    ProcedureFindRequestAction,
    procedureFindSuccessAction,
    ProcedureListRequestAction,
    procedureListSuccessAction
} from "../ducks/procedure";
import {Procedure} from "../../interfaces/Procedure";
import {showErrorToast} from '../../services/toast';

export function* findProcedure(action: ProcedureFindRequestAction) {
    try {
        const {procedureId} = action.payload;
        const findProcedureApiCall = () => {
            return api.get(`api/procedures/${procedureId}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const procedure: Procedure = yield call(findProcedureApiCall);
        yield put(procedureFindSuccessAction(procedure));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
    }
}

export function* findProcedures(action: ProcedureListRequestAction) {
    try {
        const {pageable} = action.payload;
        const findProceduresApiCall = () => {
            return api.get('api/procedures', {
                params: {
                    page: pageable.page,
                    size: pageable.size
                }
            })
                .then(response => {
                    return {procedures: response.data, totalCount: response.headers['x-total-count']};
                })
                .catch(err => {
                    throw err;
                });
        };
        const {procedures, totalCount}: { procedures: Procedure[], totalCount: number } = yield call(findProceduresApiCall);
        yield put(procedureListSuccessAction(procedures, +totalCount));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
    }
}


export default [
    takeLatest(PROCEDURE_LIST_REQUEST, findProcedures),
    takeLatest(PROCEDURE_FIND_REQUEST, findProcedure)
];
