import styled from 'styled-components';

export const ContentContainer = styled.div`
    text-align: center;
`;

export const QrCodeImage = styled.img`
`;

export const QrCodeTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    a {
        color: rgb(108, 88, 118);
    }
`;
