import React, {useEffect} from 'react';
import styled from "styled-components";
import NewsSlider from "../../NewsSlider";
import {useFindNewsHandlers, useNewsList} from "../../../store/hooks/news";
import {PAGE_SIZE} from "../../../constants";

type Props = {
};

const NewsSliderContainer = styled.div`
    background-color: #cbcad669;
    box-shadow: 0px 0px 10px #afafaf;
    display: flex; 
    padding: 0;
`;

const NewsSection = (props: Props) => {
    const newsList = useNewsList();
    const findAllNewsPaginated = useFindNewsHandlers();

    useEffect(() => {
        findAllNewsPaginated(0, PAGE_SIZE);
    }, [findAllNewsPaginated]);

    return (
        <>
            <NewsSliderContainer>
                <NewsSlider news={newsList}/>
            </NewsSliderContainer>
        </>
    );
};

export default NewsSection;
