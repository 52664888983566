import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    CHECK_PROMOTIONAL_CODE_REQUEST,
    checkPromotionalCodeErrorAction,
    CheckPromotionalCodeRequestAction,
    checkPromotionalCodeSuccessAction,
    LEAD_SEND_REQUEST,
    leadSendErrorAction,
    LeadSendRequestAction,
    leadSendSuccessAction
} from '../ducks/lead';
import {showErrorToast} from '../../services/toast';
import {Token} from '../../interfaces/Token';
import {Lead} from '../../interfaces/Lead';
import {authSuccessAction} from '../ducks/authentication';
import {getMessage} from '../../utils/LeadUtil';

export function* createLead(action: LeadSendRequestAction) {
    try {
        const {lead} = action.payload;
        const createLeadApiCall = () => {
            return api.post(`/api/register`, lead)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const checkPromoCodeApiCall = () => {
            return api.get(`/api/validate-code/${lead.promotional_code}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        if (lead.promotional_code) {
            yield call(checkPromoCodeApiCall);
        }
        const response = yield call(createLeadApiCall);
        const {token, user}: {token: Token, user: Lead} = response;
        yield put(authSuccessAction(token.token, user));
        yield put(leadSendSuccessAction());
    } catch (err) {
        console.log(err);
        yield put(leadSendErrorAction());
        showErrorToast(err.data);
    }
}

export function* checkPromotionalCode(action: CheckPromotionalCodeRequestAction) {
    try {
        const {code} = action.payload;
        const checkPromoCodeApiCall = () => {
            return api.get(`/api/validate-code/${code}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        yield call(checkPromoCodeApiCall);
        yield put(checkPromotionalCodeSuccessAction());
    } catch (err) {
        yield put(checkPromotionalCodeErrorAction());
        showErrorToast(getMessage(err.data));
    }
}

export default [
    takeLatest(LEAD_SEND_REQUEST, createLead),
    takeLatest(CHECK_PROMOTIONAL_CODE_REQUEST, checkPromotionalCode),
];
