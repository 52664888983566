import styled from 'styled-components';
import {FaQrcode} from "react-icons/fa";

export const ProfessionalItemContainer = styled.div`
    display: flex;
    background: #eae9ee;
    width: 60%;
    margin: 0 auto 4rem;
    border-radius: 10px;
    position: relative;
    color: rgba(0,0,0,.5);
    align-items: flex-start;
    @media (max-width: 1150px) {
        width: 100%;
    }
    @media (max-width: 1350px) {
        flex-direction: column;
    }
`;

export const NameLabel = styled.p`
    font-size: large;
    margin-bottom: 0.3rem;
`;

export const SpecialtyLabel = styled.p`
    text-transform: uppercase;
    font-size: x-small;
`;

export const ProfessionalImage = styled.img`
    border-radius: 10px 0 0 10px;
    max-width: 300px;
    max-height: 460px;
    @media (max-width: 1350px) {
        flex-direction: column;
        border-radius: 10px;
        margin: 0 auto;
    }
`;

export const FaQrCodeStyled = styled(FaQrcode)`
    color: #6C5876;
    margin: 0 auto;
    font-size: -webkit-xxx-large;`;

export const QrCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #6C5876;
    min-width: 110px;
    background: white;
    border-radius: 25px;
    padding: 5%;
    label {
        width: 100%;
        text-align: center;
        color: #6C5876;
        font-family: confortaa-regular;
        font-size: small;
        margin-bottom: 0;
        :hover {
            cursor: pointer;
        }
    }
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

export const QrCodeFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 1rem;
`;

export const SpaceBetweenContainer = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: space-between;
`;

export const ProfessionalData = styled.div`
    color: #6C5876;
    position: relative;
    padding: 1rem;
    @media (max-width: 1150px) {
        margin-top: 1rem;
    }
`;

export const LogoImageStyled = styled.img`
    max-width: 100px;
    margin: 0 auto;
`;

export const RegisterLabelStyled = styled.label`
    color: #6C5876;
    font-family: confortaa-bold;
    :hover {
        color: #6C5876;
        cursor: pointer;
    }
`;
