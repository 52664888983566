import styled from 'styled-components';
import {Link} from "react-router-dom";

export const AppContainer = styled.div`
    margin: 4rem 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InnerContainer = styled.div`
    width: 40%;
    @media (max-width: 650px) {
        width: unset;
    }
`;

export const NewsWrapper = styled.div`
    display: flex;
    max-height: 350px;
    background: #cbcad669;
    border-radius: 15px;
    margin: 1% 2% 5% 2%;
    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const CurrentNewsImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #cbcad669;
`;

export const CurrentNewsTextWrapper = styled.div`
    width: 100%;
    padding: 0 8%;
`;

export const NewsContainer = styled.div`
    max-width: 1150px;
    width: 100%;
`;

export const NewsPostContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2%;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 2%;
`;

export const NewsImageStyled = styled.img`
    width: 350px;
    height: 350px;
    border-radius: 15px 0 0 15px;
    @media (max-width: 1000px) {
        border-radius: 15px;
    }
`;

export const ShareIcon = styled.img`
    width: 45px;
`;

export const ShareIconContainer = styled.div`
    margin-bottom: 1%;
`;


export const LinkStyled = styled(Link)`
    color: #7e778b;
    :hover {
        color: #7e778b;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
