import React, {useCallback, useEffect} from 'react';
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import FormInput from "../../FormInput";
import RoundButton from "../../RoundButton";
import styled from "styled-components";
import Row from "../../Row";
import {ErrorMessage, Formik} from 'formik';
import ValidationSchema from './ValidationSchema';
import {MaskUtils} from '../../../utils/MasksUtil';
import {ErrorContainer, ErrorLabel} from '../../../elements/Error';
import {
    useCheckPromoCodeHandlers,
    useCreateLeadHandlers,
    useLeadLoading,
    useSuccessCheckPromoCode
} from '../../../store/hooks/lead';
import SuccessLead from './SuccessLead';
import Loading from '../../Loading';
import {InputPassword} from '../../InputPassword';
import {useCities, useFindCitiesByStateHandlers, useFindStatesHandlers, useStates} from '../../../store/hooks/address';
import {Option, Select} from '../../Select';

type Props = {};

const RegistrationSectionContainer = styled.div`
    background-color: #cbcad669; 
    padding: 2.5rem 0;
    box-shadow: 0px 0px 10px #afafaf;
`;

const RegistrationTitle = styled.h4`
    color: #6C5876;
`;

const RegistrationSectionInnerContainer = styled.div`
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    width: 30%;
    @media (max-width: 1150px) {
        width: 70%;
    }
`;

interface InitialValues {
    name: string,
    phone: string,
    email: string,
    state: string | number,
    city: string | number,
    promotional_code: string,
    password: string
}

const RegistrationSection = (props: Props) => {
    const createLeadHandlers = useCreateLeadHandlers();
    const leadLoading = useLeadLoading();
    const checkPromotionalCode = useCheckPromoCodeHandlers()
    const checkPromoSuccess = useSuccessCheckPromoCode()

    let initialValues: InitialValues = {
        name: '',
        phone: '',
        email: '',
        state: 0,
        city: 0,
        promotional_code: '',
        password: '',
    };

    const handleSubmit = (values: any) => {
        createLeadHandlers(values);
    };

    const states = useStates();
    const cities = useCities();
    const findCitiesByState = useFindCitiesByStateHandlers();
    const findStatesRequest = useFindStatesHandlers();

    const findCitiesByStateHandler = useCallback((stateId: number) => {
        if (stateId && stateId !== 0) {
            findCitiesByState(stateId);
        }
    }, [findCitiesByState]);

    useEffect(() => {
        findStatesRequest();
    }, [findStatesRequest]);

    return (
        <>
            {leadLoading && <Loading/>}
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema}
                enableReinitialize={true}>
                {({handleSubmit, values, handleChange, setFieldValue}) => (
                    <>
                        <RegistrationSectionContainer>
                            <RegistrationSectionInnerContainer>
                                <RegistrationTitle>Fazer login</RegistrationTitle>
                                <Row modifiers="small"/>
                                <FormInput type="text" placeholder="nome" name="name" value={values.name} onChange={handleChange} maxLength={255}/>
                                <FormInput type="phone" placeholder="telefone" name="phone" value={values.phone}  maxLength={255}
                                           onChange={(event: any) => setFieldValue('phone', MaskUtils.formatCel(event.target.value))}/>
                                <InputPassword type="password" placeholder="senha" name="password" value={values.password} onChange={handleChange}  maxLength={255}/>
                                <FormInput type="email" placeholder="email" name="email" value={values.email} onChange={handleChange}  maxLength={255}/>

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption
                                    value={values.state}
                                    defaultValue={0}
                                    onChange={(event: any) => {
                                        if (event !== 0) {
                                            setFieldValue('state', states.find(st => st.id === event)?.name);
                                        } else {
                                            setFieldValue('state', event);
                                        }
                                        setFieldValue('city', 0);
                                        findCitiesByStateHandler(event);
                                    }}
                                >
                                    <Option value={0}>UF</Option>
                                    {states?.map((st, i) => (
                                        <Option key={i} value={st?.id}>{st?.name}</Option>
                                    ))}
                                </Select>

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption
                                    value={values.city}
                                    onChange={(event: any) => {
                                        if (event !== 0) {
                                            setFieldValue('city', cities.find(st => st.id === event)?.name);
                                        } else {
                                            setFieldValue('city', event);
                                        }
                                    }}
                                >
                                    <Option value={0}>cidade</Option>
                                    {cities?.map((ci, i) => (
                                        <Option key={i} value={ci?.id}>{ci?.name}</Option>
                                    ))}
                                </Select>
                                <FormInput type="text" placeholder="código promocional" name="promotional_code"
                                           value={values.promotional_code}
                                           onChange={handleChange}
                                           maxLength={255}/>
                                <Row>
                                    <ErrorContainer>
                                        <ErrorMessage name="name" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                        <ErrorMessage name="phone" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                        <ErrorMessage name="email" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                        <ErrorMessage name="state" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                        <ErrorMessage name="city" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                        <ErrorMessage name="password" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                    </ErrorContainer>
                                </Row>
                                <SuccessLead/>
                                <ButtonToolbar style={{margin: '0 auto'}}>
                                    <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                        ENVIAR
                                    </RoundButton>
                                </ButtonToolbar>
                            </RegistrationSectionInnerContainer>
                        </RegistrationSectionContainer>
                    </>
                )}
            </Formik>
        </>
    );
};

export default RegistrationSection;
