import React from 'react';
import {Container, IconFacebook, Label} from './styles';

const FacebookButton = (props: any) => {
    return (
        <Container {...props}>
            <IconFacebook/>
            <Label>Entrar com o facebook</Label>
        </Container>
    )
};

export default FacebookButton;
