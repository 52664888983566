import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {showErrorToast} from '../../services/toast';

import {
    NEWS_FIND_ALL_REQUEST,
    NEWS_FIND_ONE_REQUEST,
    NewsFindAllRequestAction,
    newsFindAllSuccessAction,
    NewsFindOneRequestAction, newsFindOneSuccessAction
} from "../ducks/news";
import {News} from "../../interfaces/News";

export function* findAllNews(action: NewsFindAllRequestAction) {
    try {
        const {page, size} = action.payload;
        const findAllNewsApiCall = () => {
            return api.get(`api/news`, {
                params:
                    {
                        page,
                        size
                    }
            })
                .then(response => {
                    const newArr: any = [];
                    response.data.data.forEach( (n: any, i: any)=> {
                        if (i % 3 === 0) {
                            newArr.push([n]);
                        } else {
                            newArr[Math.ceil(i/3) - 1].push(n);
                        }
                    });
                    return {...response.data, data: newArr};
                })
                .catch(err => {
                    throw err;
                });
        };
        const {data, total}: {data: News[], total: number} = yield call(findAllNewsApiCall);
        yield put(newsFindAllSuccessAction(data, +total));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
    }
}

export function* findOneNews(action: NewsFindOneRequestAction) {
    try {
        const {id} = action.payload;
        const findOneNewsApiCall = () => {
            return api.get(`api/news/${id}`)
                .then(response => {
                    return {news: response.data};
                })
                .catch(err => {
                    throw err;
                });
        };
        const {news}: { news: any } = yield call(findOneNewsApiCall);
        news.content = {__html: news.content};
        news.embedVideo = {__html: news.embed_video};
        yield put(newsFindOneSuccessAction(news));
    } catch (err) {
        console.log(err);
        showErrorToast(err.data.detail || err.data.title);
    }
}

export default [
    takeLatest(NEWS_FIND_ALL_REQUEST, findAllNews),
    takeLatest(NEWS_FIND_ONE_REQUEST, findOneNews)
];
