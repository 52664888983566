import React from 'react';
import RoundButton from "../RoundButton";
import {ButtonContainer, EmptyNewsTitle, NewsContainer, NewsContent, NewsImageStyled} from "./styles";
import {Link} from "react-router-dom";
import useBreakpoint from "../../store/hooks/breakpoint";

const reactSlideshow = require('react-slideshow-image');

type Props = {
    news: any[]
};

const NewsSlider = (props: Props) => {
    const breakpoint = useBreakpoint();
    const slideProperties = {
        autoplay: false,
        duration: 3000,
        transitionDuration: 500,
        infinite: true,
        arrows: true,
        indicators: false,
    };

    return (
        <div className="slide-container" style={{width: '90%'}}>
            { breakpoint === 'lg' && props?.news?.length > 0 &&
                <reactSlideshow.Slide {...slideProperties}>
                    {  props?.news?.map( (a, i) =>
                        <div>
                            <div key={i} className="each-fade">
                                { a?.map( (p: any, y: any) =>
                                    <NewsContainer key={y}>
                                        <NewsImageStyled src={`data:image/png;base64,${p?.image_preview}`}/>
                                        <NewsContent>
                                            <h4>{p.title}</h4>
                                            {/*<h5>{p.author}</h5>
                                                <label>{p.preview}</label>*/}
                                            <ButtonContainer>
                                                <Link to={`/news/${p.id}`}>
                                                    <RoundButton variant="primary" size="sm" type="submit">
                                                        SAIBA MAIS
                                                    </RoundButton>
                                                </Link>
                                            </ButtonContainer>
                                        </NewsContent>
                                    </NewsContainer>
                                )}
                            </div>
                        </div>
                    )}
                </reactSlideshow.Slide>
            }
            { breakpoint !== 'lg' && props?.news?.length > 0 &&
                <reactSlideshow.Slide {...slideProperties}>
                    {  props?.news?.flat()?.map( (a, i) =>
                        <div key={i} className="each-fade">
                            <NewsContainer key={i}>
                                <NewsImageStyled src={`data:image/png;base64,${a?.image_preview}`}/>
                                <NewsContent>
                                    <h4>{a.title}</h4>
                                    {/*<h5>{p.author}</h5>
                                        <label>{p.preview}</label>*/}
                                    <ButtonContainer>
                                        <Link to={`/news/${a.id}`}>
                                            <RoundButton variant="primary" size="sm" type="submit">
                                                SAIBA MAIS
                                            </RoundButton>
                                        </Link>
                                    </ButtonContainer>
                                </NewsContent>
                            </NewsContainer>
                        </div>
                    )}
                </reactSlideshow.Slide>
            }
            { props?.news?.length === 0 &&
                <EmptyNewsTitle>Não há notícias no momento.</EmptyNewsTitle>
            }
        </div>
    );
};

export default NewsSlider;
