import { Input as InputAntd } from 'antd';
import styled from 'styled-components';

export const InputPassword = styled(InputAntd.Password)`
    input {
        font-size: 1rem;
        border-radius: 25px;
        margin: 0.5rem 0;
        text-align: center !important;
    }
`;
