import styled from 'styled-components';
import {Link} from "react-router-dom";

export const LogoImageStyled = styled.img`
    max-width: 100px;
    margin: 0 auto;
`;

export const LoginTitle = styled.p`
    margin-top: 1rem;
`;


export const RegisterLabelStyled = styled.label`
    color: #6C5876;
    font-family: confortaa-bold;
    :hover {
        color: #6C5876;
        cursor: pointer;
    }
`;

export const RegisterLinkStyled = styled(Link)`
    color: #6C5876;
    font-family: confortaa-bold;
    :hover {
        color: #6C5876;
        cursor: pointer;
    }
`;

export const LoginContainer = styled.div`
    text-align: center;
`;

export const ForgotPwdLine = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
    a {
        margin: 0 auto;
    }
`;

export const SocialLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      margin-bottom: 5px;
    }
`;

export const OrContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 5px;
    margin-bottom: 5px;
    span {
        z-index: 1;
        box-sizing: border-box;
        padding: 0 10px;
        background: #fff;
        text-transform: uppercase;
    }
    
    :before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      top: 50%;
      background: #D3D3D3;
    }
`;
