import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";


export const NavDrop = styled(NavDropdown)`
    width: 20%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-transform: uppercase;
    color: rgba(0,0,0,.5) !important;
    text-align: center;
    color: rgba(0,0,0,.5);
    margin: 0 auto;
    @media (max-width: 1150px) {
        width: 100%;
    }
    label {
        cursor: pointer;
    }
    a {
        padding-top: 0;
    }
`;

export const NavLink = styled(Nav.Link)`
    width: 20%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-transform: uppercase;
    color: rgba(0,0,0,.5) !important;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 1150px) {
        width: 100%;
    }
    label {
        cursor: pointer;
    }
`;

export const NavDropItem = styled(NavDropdown.Item)`
    color: rgba(0,0,0,.5);
    :focus, :active {
        color: rgba(0,0,0,.5);
        background-color: unset;
        outline: none;
    }
    a: active {
        color: rgba(0,0,0,.5);
        background-color: unset;
    }
`;

export const LogoImageStyled = styled.img`
    max-width: 50px;
    margin: 0 auto;
`;

type NavbarProps = {
    hidemenu?: boolean
}

export const NavBarStyled = styled(Navbar)<NavbarProps>`
    top: ${props => props.hidemenu ? '-80px !important' : '0 !important' };
    box-shadow: 1px 1px 10px grey;
    position: fixed;
    width: 100%;
    transition: all 0.9s ease;
`;

export const NavStyled = styled(Nav)`
    margin: 0 auto;
    width: 80%;
    @media (max-width: 1150px) {
        width: 100%;
        text-align: center;
    }    
`;