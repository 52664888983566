import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    AUTH_FACEBOOK_REQUEST,
    AUTH_GOOGLE_REQUEST,
    AUTH_REQUEST,
    AuthFacebookRequestAction,
    authFailedAction,
    AuthGoogleRequestAction,
    authIncompleteAction,
    AuthRequestAction,
    authSuccessAction,
    CHANGE_PASSWORD_REQUEST, changePasswordErrorAction,
    ChangePasswordRequestAction,
    changePasswordSuccessAction, COMPLETE_USER_DATA_REQUEST, completeUserDataErrorAction,
    CompleteUserDataRequestAction, completeUserDataSuccessAction,
    FORGOT_PASSWORD_REQUEST,
    ForgotPasswordRequestAction,
    forgotPasswordSuccessAction,
} from '../ducks/authentication';
import {Token} from '../../interfaces/Token';
import {Lead} from '../../interfaces/Lead';
import {showErrorToast} from "../../services/toast";

export function* authenticate(action: AuthRequestAction) {
    try {
        const {username, password} = action.payload;
        const req = {
            username: username,
            password: password
        };
        const authApiCall = () => {
            return api.post('api/auth/authenticate', req)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        const response: any = yield call(authApiCall);
        const {token, user}: {token: Token, user: Lead} = response;
        yield put(authSuccessAction(token.token, user));
    } catch (err) {
        console.log(err);
        yield put(authFailedAction(err));
    }
}

export function* authenticateFacebook(action: AuthFacebookRequestAction) {
    try {
        const {access_token} = action.payload;
        const authFacebookApiCall = () => {
            return api.post('api/auth/facebook', {access_token})
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        const response: any = yield call(authFacebookApiCall);
        const {token, user}: {token: Token, user: Lead} = response;
        if (!user.phone || !user.state || !user.city) {
            yield put(authIncompleteAction(token.token, user));
        } else {
            yield put(authSuccessAction(token.token, user));
        }
    } catch (err) {
        console.log(err);
        yield put(authFailedAction(err));
    }
}

export function* authenticateGoogle(action: AuthGoogleRequestAction) {
    try {
        const {access_token} = action.payload;
        const authGoogleApiCall = () => {
            return api.post('api/auth/google', {access_token})
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        const response: any = yield call(authGoogleApiCall);
        const {token, user}: {token: Token, user: Lead} = response;
        if (!user.phone || !user.state || !user.city) {
            yield put(authIncompleteAction(token.token, user));
        } else {
            yield put(authSuccessAction(token.token, user));
        }
    } catch (err) {
        yield put(authFailedAction(err));
    }
}

export function* completeUserData(action: CompleteUserDataRequestAction) {
    try {
        const {user} = action.payload;
        const completeUserDataApiCall = () => {
            return api.put(`api/register/complete/${user.id}`, user)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        yield call(completeUserDataApiCall);
        yield put(completeUserDataSuccessAction());
    } catch (err) {
        showErrorToast('Erro ao completar cadastro.');
        yield put(completeUserDataErrorAction());
    }
}

export function* requestNewPassword(action: ForgotPasswordRequestAction) {
    try {
        const {username} = action.payload;
        const req = {
            username: username
        };
        const authApiCall = () => {
            return api.post('api/reset-password', req)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        yield call(authApiCall);
        yield put(forgotPasswordSuccessAction());
    } catch (err) {
        yield put(authFailedAction(err));
        showErrorToast(err.data);
    }
}

export function* changePassword(action: ChangePasswordRequestAction) {
    try {
        const {changePasswordRequest} = action.payload;
        const req = {
            password: changePasswordRequest.password
        };
        const changePasswordApiCall = () => {
            return api.post(`api/change-password/${changePasswordRequest.resetHash}`, req)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        yield call(changePasswordApiCall);
        yield put(changePasswordSuccessAction());
    } catch (err) {
        showErrorToast('Ocorreu um erro ao trocar de senha.');
        yield put(changePasswordErrorAction());
    }
}

export default [
    takeLatest(AUTH_REQUEST, authenticate),
    takeLatest(COMPLETE_USER_DATA_REQUEST, completeUserData),
    takeLatest(AUTH_FACEBOOK_REQUEST, authenticateFacebook),
    takeLatest(AUTH_GOOGLE_REQUEST, authenticateGoogle),
    takeLatest(FORGOT_PASSWORD_REQUEST, requestNewPassword),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
];
