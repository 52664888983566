import styled from 'styled-components';
import {Link} from "react-router-dom";

export const ServiceSelectionStyle = styled.div`
    width: 80%;
    margin: 2rem auto 4rem;
`;

export const SliderSearchStyled = styled.div`
    width: 100%;
    text-align: center;
`;

export const LinkStyled = styled(Link)`
    color: rgba(0,0,0,0.5);
    :focus {
        outline: none;
    }
    :hover {
        color: rgba(0,0,0,0.5);
    }
`;

