import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {
    clearProfessionalListAction, clearQrCodeAction,
    generateQrCodeRequestAction,
    professionalListRequestAction
} from "../ducks/professional";
import {QrCodeGenerateRequest} from "../../interfaces/QrCodeGenerateRequest";

export const useProfessionalsList = () => {
    return useSelector((state: GlobalState) => state.professional.professionalList);
};

export const useProfessionalLoading = () => {
    return useSelector((state: GlobalState) => state.professional.loading);
};

export const useGeneratedQrCode = () => {
    return useSelector((state: GlobalState) => state.professional.generatedQrCode);
};

export const useGenerateQrCodeHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((data: QrCodeGenerateRequest) => {
        dispatch(generateQrCodeRequestAction(data))
    }, [dispatch]);
};

export const useFindProfessionalsHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((city: string) => {
        dispatch(professionalListRequestAction(city))
    }, [dispatch]);
};

export const useClearProfessionalList = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearProfessionalListAction())
    }, [dispatch]);
};

export const useClearQrCode = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearQrCodeAction())
    }, [dispatch]);
};
