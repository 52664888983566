import React, {useEffect, useState} from 'react';
import ValidationSchemaLogin from "./ValidationSchemaLogin";
import ValidationSchemaForgotPassword from "./ValidationSchemaForgotPassword";
import Row from "../Row";
import FormInput from "../FormInput";
import {ErrorContainer, ErrorLabel} from "../../elements/Error";
import {ErrorMessage, Formik} from "formik";
import RoundButton from "../RoundButton";
import lileveLogo from "../../assets/images/lileve-logo-gray-sm.png";
import {
    ForgotPwdLine,
    LoginContainer,
    LoginTitle,
    LogoImageStyled,
    OrContainer,
    RegisterLabelStyled,
    RegisterLinkStyled,
    SocialLoginContainer
} from "./styles";
import {
    useAuthFacebookHandlers,
    useAuthGoogleHandlers,
    useAuthHandlers, useAuthLoading,
    useClearForgotPasswordState,
    useForgotPasswordRequest,
    useHideLoginHandler
} from "../../store/hooks/authentication";
import {Login} from "../../pages/HomePage";
import SuccessForgotPassword from "./SuccessForgotPassword";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID} from '../../environments';
import FacebookButton from '../FacebookButton';
import GoogleButton from '../GoogleButton';
import Loading from "../Loading";

const scrollToComponent = require('react-scroll-to-component');

interface InitialValues {
    username: string,
    password: string
}

const LoginForm = () => {
    const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
    const loading = useAuthLoading();
    const authHandlers = useAuthHandlers();
    const authFacebookHandlers = useAuthFacebookHandlers();
    const authGoogleHandlers = useAuthGoogleHandlers();
    const requestNewPassword = useForgotPasswordRequest();
    const hideLoginDialog = useHideLoginHandler();
    const clearForgotPassword = useClearForgotPasswordState();

    let initialValues: InitialValues = {
        username: '',
        password: ''
    };

    const handleSubmitLogin = (values: any) => {
        authHandlers(values);
    };

    const handleSubmitForgotPassword = (values: any) => {
        requestNewPassword(values);
    };


    const responseFacebook = (response: any) => {
        if (response['accessToken']) {
            authFacebookHandlers(response['accessToken']);
        }
    };

    const responseGoogle = (response: any) => {
        if (response['accessToken']) {
            authGoogleHandlers(response['accessToken']);
        }
    };

    useEffect(() => {
        clearForgotPassword();
        return () => clearForgotPassword();
    }, [clearForgotPassword]);

    return (
        <LoginContainer>
            {loading && <Loading/>}
            <LogoImageStyled src={lileveLogo}/>
            <LoginTitle>{forgotPasswordVisible ? 'Recuperação de senha' : 'Login'}</LoginTitle>
            {
                !forgotPasswordVisible &&
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitLogin}
                    validationSchema={ValidationSchemaLogin}
                    enableReinitialize={true}>
                    {({handleSubmit, values, handleChange, setFieldValue}) => (
                        <>
                            <Row modifiers="small"/>
                            <FormInput type="text" placeholder="e-mail" name="username" value={values.username}
                                       onChange={handleChange} maxLength={255}/>
                            <FormInput type="password" placeholder="senha" name="password" value={values.password}
                                       maxLength={255}
                                       onChange={handleChange}/>
                            <ErrorContainer>
                                <ErrorMessage name="username" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                <ErrorMessage name="password" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                            </ErrorContainer>

                            <ForgotPwdLine>
                                <RegisterLabelStyled onClick={() => {
                                    setForgotPasswordVisible(true)
                                }}>Esqueci minha senha</RegisterLabelStyled>
                            </ForgotPwdLine>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                    ENTRAR
                                </RoundButton>
                            </div>

                            <OrContainer>
                                <span>OU</span>
                            </OrContainer>

                            <SocialLoginContainer>
                                <FacebookLogin
                                    appId={FACEBOOK_CLIENT_ID}
                                    callback={responseFacebook}
                                    render={(renderProps: any) => (
                                        <FacebookButton onClick={(event: any) => renderProps.onClick(event)}/>
                                    )}
                                />
                                <GoogleLogin
                                    clientId={GOOGLE_CLIENT_ID}
                                    render={(renderProps: any) => (
                                        <GoogleButton onClick={(event: any) => renderProps.onClick(event)}/>
                                    )}
                                    onSuccess={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </SocialLoginContainer>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <label>Não possui uma conta?</label>
                                <RegisterLinkStyled onClick={() => {
                                    hideLoginDialog();
                                    setTimeout(() => scrollToComponent(Login, {
                                        offset: -300,
                                        align: 'top',
                                        duration: 500,
                                        ease: 'inExpo'
                                    }), 600);
                                }} to={"/home"}>Registrar</RegisterLinkStyled>
                            </div>
                        </>
                    )}
                </Formik>
            }
            {forgotPasswordVisible &&
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForgotPassword}
                validationSchema={ValidationSchemaForgotPassword}
                enableReinitialize={true}>
                {({handleSubmit, values, handleChange, setFieldValue}) => (
                    <>
                        <Row modifiers="small"/>
                        <FormInput type="text" placeholder="e-mail" name="username" value={values.username}
                                   onChange={handleChange} maxLength={255}/>
                        <ErrorContainer>
                            <ErrorMessage name="username" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                        </ErrorContainer>
                        <SuccessForgotPassword/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                SOLICITAR NOVA SENHA
                            </RoundButton>
                        </div>
                        <RegisterLabelStyled onClick={() => {
                            setForgotPasswordVisible(false)
                        }}>Voltar</RegisterLabelStyled>
                    </>
                )}
            </Formik>
            }
        </LoginContainer>
    );
};

export default LoginForm;
