export interface Procedure {
    id?: number,
    name?: string,
    shortDesc?: string,
    fullDesc?: string,
    image?: any,
    imageContentType?: string,
    minValue?: number,
    maxValue?: number,
    average?: number,
    active?: boolean,
    basicInfo?: BasicInfo,
    treatment?: string,
    faqs?: Faq[],
    type: ProcedureType
}

export interface BasicInfo {
    title?: string,
    desc?: string
}

export interface Faq {
    id?: number,
    question?: string,
    answer?: string
}

export enum ProcedureType {
    FACIAL = 'FACIAL',
    NON_SURGICAL = 'NON_SURGICAL',
    BREAST = 'BREAST',
    BODY = 'BODY'
}
