import React, {useCallback, useEffect, useState} from 'react';
import SectionTitle from "../../components/SectionTitle";
import Paragraph from "../../components/Paragraph";
import RoundButton from "../../components/RoundButton";
import styled from "styled-components";
import {
    useAddressLoading,
    useCities,
    useFindCitiesByStateHandlers,
    useFindStatesHandlers,
    useStates
} from "../../store/hooks/address";
import {
    useClearProfessionalList,
    useFindProfessionalsHandlers,
    useProfessionalLoading,
    useProfessionalsList
} from "../../store/hooks/professional";
import Loading from "../../components/Loading";
import {ErrorMessage, Formik} from 'formik';
import ValidationSchema from './ValidationSchema';
import {ErrorContainer, ErrorLabel} from '../../elements/Error';
import './styles.css';
import ProfessionalItem from "../../components/ProfessionalItem";
import Reveal from "react-reveal/Reveal";
import {Option, Select} from '../../components/Select';
import {InvisibleMarker} from "./styles";

const scrollToComponent = require('react-scroll-to-component');

type Props = {};

const ContentBlock = styled.div`
    background-color: #cbcad669;
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    margin-bottom: 7rem;
    @media (max-width: 1150px) {
        flex-direction: column;
        margin-bottom: 2rem;  
    }
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    width: 100%;
`;

const ProfessionalsSearch = (props: Props) => {
    const findStatesRequest = useFindStatesHandlers();
    const findProfessionalsByCityRequest = useFindProfessionalsHandlers();
    const states = useStates();
    const cities = useCities();
    const findCitiesByState = useFindCitiesByStateHandlers();
    const professionalList = useProfessionalsList();
    const [submitted, setSubmitted] = useState(false);
    const loadingProfessional = useProfessionalLoading();
    const loadingAddress = useAddressLoading();
    const clearProfessionalList = useClearProfessionalList();
    let professionalResults: any;

    useEffect(() => {
        findStatesRequest();
    }, [findStatesRequest]);

    useEffect(() => {
        if ((professionalList && professionalList.length > 0) || submitted) {
            scrollToComponent(professionalResults, { offset: -300, align: 'top', duration: 500, ease:'inExpo'})
        }
    }, [professionalList, professionalResults, submitted]);

    useEffect(() => {
        return () => {
            clearProfessionalList();
        };
    }, [clearProfessionalList]);

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

    const findCitiesByStateHandler = useCallback((stateId: number) => {
        if (stateId && stateId !== 0) {
            findCitiesByState(stateId);
        }
    }, [findCitiesByState]);

    const initialValues: { stateId: number, cityId: number } = {
        stateId: 0,
        cityId: 0
    };

    const handleSubmit = (values: any) => {
        handleSearchProfessionals(values.cityId);
    };

    const handleSearchProfessionals = useCallback((city: number) => {
        if (cities) {
            let cityF = cities.find(c => c.id === city);
            if (cityF != null && cityF.name != null) {
                findProfessionalsByCityRequest(cityF.name);
                setTimeout(() => {
                    setSubmitted(true);
                }, 1000);
            }
        }
    }, [cities, setSubmitted, findProfessionalsByCityRequest]);

    return (
        <div style={{marginTop: '4rem'}}>
            {(loadingProfessional || loadingAddress) && <Loading/>}
            <SectionTitle title="Profissionais"/>
            <ContentBlock>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={ValidationSchema}
                    enableReinitialize={true}>
                    {({handleSubmit, values, handleChange, setFieldValue}) => (
                        <TextBlock>
                            <Paragraph style={{color: '#6C5876'}} modifiers={["center", "xlarge"]}>
                                Para melhorar sua busca, selecione seu Estado e Cidade
                            </Paragraph>
                            <div>
                                <div style={{width: '50%', margin: '0 auto', textAlign: 'center'}}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption
                                        value={values.stateId}
                                        defaultValue={0}
                                        onChange={(event: any) => {
                                            setFieldValue('stateId', event);
                                            setFieldValue('cityId', 0);
                                            findCitiesByStateHandler(event);
                                        }}
                                    >
                                        <Option value={0}>UF</Option>
                                        {states?.map((st, i) => (
                                            <Option key={i} value={st?.id}>{st?.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '50%', margin: '1rem auto', textAlign: 'center'}}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption
                                        value={values.cityId}
                                        onChange={(event: any) => setFieldValue('cityId', event)}
                                    >
                                        <Option value={0}>Cidade</Option>
                                        {cities?.map((ci, i) => (
                                            <Option key={i} value={ci?.id}>{ci?.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <ErrorContainer>
                                <ErrorMessage name="stateId" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                <ErrorMessage name="cityId" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                            </ErrorContainer>
                            <Paragraph modifiers="center">
                                <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                    BUSCAR
                                </RoundButton>
                            </Paragraph>
                        </TextBlock>
                    )}
                </Formik>
                <InvisibleMarker ref={(element: any) => { professionalResults = element; }}>.</InvisibleMarker>
            </ContentBlock>
            {submitted && professionalList?.length === 0 &&
            <h3 style={{marginBottom: '3rem'}}>Não foram encontrados profissionais com esses critérios.</h3>
            }
            {professionalList.map((professional: any, index: number) => (
                <Reveal key={index} effect="fadeInUp">
                    <ProfessionalItem professional={professional}/>
                </Reveal>
            ))
            }
        </div>
    );
};

export default ProfessionalsSearch;
