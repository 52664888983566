import styled from 'styled-components';
import {Link} from "react-router-dom";

export const AppContainer = styled.div`
    margin: 4rem 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InnerContainer = styled.div`
    width: 40%;
    @media (max-width: 650px) {
        width: unset;
    }
`;

export const NewsContentContainer = styled.div`
    padding: 0 10%;
    @media (max-width: 1024px) {
      padding: 0 2%;
    }
`;

export const CurrentNewsImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #cbcad669;
    overflow: hidden;
    padding: 5% 0;
`;

export const IframeContainer = styled.div`
    max-width: 100%;
    float: left;
    margin: 30px;
    @media (max-width: 1024px) {
      float: unset;
      display: flex;
      justify-content: center;
    }
    iframe {
        @media (max-width: 650px) {
            width: 100%;
        }
    }
`;

export const CurrentNewsTextWrapper = styled.div`
    width: 100%;
    padding: 0 8%;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 2%;
`;

export const NewsImageStyled = styled.img`
    max-width: 350px;
    float: left;
    width: 30%;
    margin: 30px;
    border-radius: 15px;
`;

export const ShareIcon = styled.img`
    width: 45px;
`;

export const ShareIconContainer = styled.div`
`;


export const LinkStyled = styled(Link)`
    color: #7e778b;
    :hover {
        color: #7e778b;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
