import styled from 'styled-components';

export const FooterSilicredContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
    padding: 2.5rem 0;
    margin-top: 12rem;
    box-shadow: -1px -1px 10px #afafaf;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

export const CenteredContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1150px) {
        flex-direction: row;
    }
    a {
        margin: 2rem;
    }
`;