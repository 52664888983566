import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    addressRequestErrorAction,
    FIND_CITIES_BY_STATE_REQUEST,
    FIND_STATES_REQUEST,
    FindCitiesByStateRequestAction,
    findCitiesSuccessAction,
    FindStatesRequestAction,
    findStatesSuccessAction
} from "../ducks/address";
import {State} from "../../interfaces/State";
import {City} from "../../interfaces/City";
import {showErrorToast} from '../../services/toast';

// export function* findAddressByCep(action: AddressByCepRequestAction) {
//     try {
//         const {cep} = action.payload;
//         const findAddressByCepApiCall = () => {
//             return api.get(`address/cep/${cep}`)
//                 .then(response => {
//                     return response.data;
//                 })
//                 .catch(err => {
//                     throw err;
//                 });
//         };
//         const address: Address = yield call(findAddressByCepApiCall);
//         yield put(addressByCepSuccessAction(address));
//     } catch (err) {
//         yield put(addressRequestErrorAction());
//     }
// }

export function* findStates(action: FindStatesRequestAction) {
    try {
        const findStatesApiCall = () => {
            return api.get(`api/address/states`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const states: State[] = yield call(findStatesApiCall);
        yield put(findStatesSuccessAction(states));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
        yield put(addressRequestErrorAction());
    }
}

export function* findCitiesByState(action: FindCitiesByStateRequestAction) {
    try {
        const {stateId} = action.payload;
        const findCitiesByStateApiCall = () => {
            return api.get(`api/address/city/${stateId}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const cities: City[] = yield call(findCitiesByStateApiCall);
        yield put(findCitiesSuccessAction(cities));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
        yield put(addressRequestErrorAction());
    }
}

export default [
    takeLatest(FIND_CITIES_BY_STATE_REQUEST, findCitiesByState),
    takeLatest(FIND_STATES_REQUEST, findStates),
];
