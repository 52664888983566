import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 7px 0;
  text-align: center;
  transition: 0.5s all ease;
`;

export const ErrorLabel = styled.span`
    font-size: 13px;
    color: red;
    transition: 0.5s all ease;
`;
