import styled from "styled-components";
import Button from "react-bootstrap/Button";

type Props = {
    bgcolor?: string;
    alter?: number;
}

const RoundButton = styled(Button)<Props>`
    border-radius: 35px;
    margin: 0.5rem 0;
    text-align: center;
    padding: 0.3rem 2.5rem;
    border: none;
    ${props => props.alter ? `
        padding: 0.7rem 2.5rem;
        background-color: white;
        color: #6c5876;
        box-shadow: 1px 1px 8px grey;
        text-transform: lowercase;
        font-family: OpenSans-Semibold, "Open Sans Semibold", sans-serif;
        font-weight: 600;
    ` : `
        background-color: ${props.bgcolor ? props.bgcolor : '#7e778c'};
        text-transform: uppercase;
        font-family: confortaa-regular;
    `};
    :hover, :focus, :active {
        background-color: #716b7d !important;
        border-color: none !important;
        box-shadow: none !important;
    }
`;

export default RoundButton;
