import * as Yup from 'yup';

const schema = Yup.object().shape({
    stateId: Yup.number()
        .min(1, "Selecione um estado para continuar a busca"),
    cityId: Yup.number()
        .min(1, "Selecione uma cidade para continuar a busca"),
});

export default schema;
