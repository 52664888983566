import React from 'react';
import flowerWhite from "./../../assets/images/flowerwhite.png";
import wingsLeft from "./../../assets/images/wingsCutLeft.png";
import wingsRight from "./../../assets/images/wingsCutRight.png";
import styled from "styled-components";
import LogoWrapper from "../LogoWrapper";

const reactSlideshow = require('react-slideshow-image');

type Props = {
};

const TextLayer = styled.div`
    display: flex; 
    flex-direction: column; 
    position: absolute;
    top: 0;
    z-index: 1;
    height: 80%;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 900px) {

    }
`;

const ImageLayer = styled.img`
    position: absolute; 
    top: 20%;
    z-index: 1;
    @media (max-width: 1150px) {
        width: 25%;
    }
`;

const LogoImage = styled.img`
    align-self: center; 
    width: 80px;
`;

const HiddenTitleSpacer = styled.h1`
    opacity: 0;
    @media (max-width: 1150px) {
        display: none;  
    }
`;

const PageTitleStyled = styled.div`
    font-weight: bolder;
    text-shadow: 1px 1px 10px gray;
    font-size: 52px;
    margin-left: 10%;
    margin-bottom: 5%;
    max-width: 350px; 
    display: block;
    text-align: left;
    @media (max-width: 1150px) {
      margin-left: 5px;
      h1 {
        font-size: 27px;
        font-weight: bolder;
        text-align: left;
      }
    }
`;


const ImageSlider = (props: Props) => {
    const fadeProperties = {
        duration: 3000,
        transitionDuration: 500,
        infinite: true,
        arrows: false,
        indicators: false,
    };

    return (
        <div className="slide-container">
            <div className="image-container-x" />
            <reactSlideshow.Fade {...fadeProperties}>
                <div className="each-fade">
                    <div className="image-container image-container-1">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-2">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-3">
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container image-container-4">
                    </div>
                </div>
            </reactSlideshow.Fade>
            <TextLayer>
                <LogoWrapper>
                    <LogoImage src={flowerWhite}/>
                </LogoWrapper>
                <PageTitleStyled>
                    <h1>Bem-vinda ao mundo da cirurgia plástica</h1>
                </PageTitleStyled>
            </TextLayer>
        </div>
    );
};

export default ImageSlider;
