import styled from "styled-components";
import {FaPhone} from "react-icons/fa";

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10;
    right: 10px;
    margin-top: 1rem;
    z-index: 1;
    a {
        margin: 0 5px;
    }
    @media (max-width: 1150px) {
        position: relative;
    }
`;

export const IconAnchorStyled = styled.a`
    background: white;
    min-width: 35px;
    border-radius: 25px;
    padding: 1%;
`;

export const PhoneContainer = styled.div`
    background: white;
    padding-right: 2.5rem;
    position: relative;
    border-radius: 35px;
    padding-right: 2.5rem;
    padding-left: 1.5rem;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
`;

export const FaPhoneStyled = styled(FaPhone)`
    color: gray;
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 1.8rem;
    padding-top: 0.35rem;
`;

export const LabelPhone = styled.div`
    vertical-align: sub;
    font-size: small;
`;

export const AnchorPhone = styled.a`
    color: unset;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5rem;
    padding-top: 0.5rem;
`;
