import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleWare from 'redux-saga';
import reducers, {GlobalState} from './ducks';
import rootSaga from "./saga";
import {PersistConfig} from "redux-persist/es/types";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig: PersistConfig<GlobalState> = {
    key: 'lileve',
    storage,
    whitelist: ['auth'],

};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleWare();

const store = createStore(
    persistedReducer,
    applyMiddleware(sagaMiddleware),
);

export let persistor = persistStore(store);

export default {
    store,
    persistor
};


sagaMiddleware.run(rootSaga);
