import React from 'react';
import RoundButton from "../../RoundButton";
import styled from "styled-components";
import {BannerTitle, ButtonFooter, TextBlock} from "../styles";
import {Link} from "react-router-dom";
import professional from "../../../assets/images/profissionais3.jpg";
import Row from "../../Row";

type Props = {
};

const ContentBlock = styled.div`
    background-color: #cbcad669;
    display: flex ;
    box-shadow: 0px 0px 10px #afafaf;
    position: relative;
    padding: 0 10%;
    @media (max-width: 1150px) {
        flex-direction: column;   
        padding: 0; 
    }
`;

const ProfessionalSection = (props: Props) => {
    return (
        <div>
            <TextBlock background={professional}>
                <Row modifiers="small"/>
                    <div>
                        <BannerTitle align="left" color="#6C5876">
                        A melhor rede de atendimento
                        </BannerTitle>
                    </div>
                    <div>
                        <BannerTitle color="white" align="right">com profissionais de renome</BannerTitle>
                    </div>
                    <ButtonFooter>
                        <Link to={"/search-professionals"}>
                        <RoundButton variant="primary" alter={1} size="sm">
                        agende sua avaliação
                        </RoundButton>
                        </Link>
                    </ButtonFooter>
                <Row modifiers="small"/>
            </TextBlock>
        </div>
    );
};

export default ProfessionalSection;
