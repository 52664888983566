import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    background: #80808085;
    align-items: center;
`;
