import React, {useCallback, useEffect, useState} from 'react';
import Navbar from "react-bootstrap/Navbar";
import lileveLogo from "../../assets/images/lileve-logo-gray-sm.png";
import {LogoImageStyled, NavBarStyled, NavDrop, NavDropItem, NavLink, NavStyled} from "./styles";
import {Company, Login, News, Procedures, Professionals} from "../../pages/HomePage";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {withRouter} from "react-router";
import {useAuthenticated, useAuthUser, useLogoutHandler, useShowLoginHandler} from "../../store/hooks/authentication";

const scrollToComponent = require('react-scroll-to-component');

type Props = {
    history: any,
    location: any,
    match: {
        params: {
            id: string
        }
    }
}

const TopMenu = (props: Props) => {
    const [hideOnScroll, setHideOnScroll] = useState(true);
    const [pathname, setPathname] = useState('');
    const authenticated = useAuthenticated();
    const loggedUser = useAuthUser();
    const logout = useLogoutHandler();
    const showLogin = useShowLoginHandler();

    props.history.listen((location: any, action: any) => {
        setPathname(location.pathname);
        window.scrollTo(0, 0);
    });

    const shouldShowMenu = useCallback(() => {
        return pathname.indexOf('procedure') === -1
            && pathname.indexOf('professional') === -1
            && pathname.indexOf('news') === -1
            && pathname.indexOf('appointment-data') === -1
            && pathname.indexOf('reset') === -1;
    }, [pathname]);

    useScrollPosition(({ prevPos, currPos }) => {
        const isHidden = currPos.y === 0 && shouldShowMenu();
        setHideOnScroll(isHidden);
    }, [hideOnScroll, pathname, shouldShowMenu]);

    useEffect( () => {
        const isHidden = shouldShowMenu();
        setHideOnScroll(isHidden);
    }, [props, setHideOnScroll, shouldShowMenu]);

    return (
        <>
            <NavBarStyled bg="light" expand="lg" sticky="top" hidemenu={hideOnScroll ? 1 : 0}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <NavStyled>
                        <NavLink
                            onClick={() => {
                                setTimeout( () => window.scroll(0,0));
                            }}
                            href="#home"><label>Home</label></NavLink>
                        <NavLink
                            onClick={() => {
                                setTimeout( () => scrollToComponent(Company, { offset: -300, align: 'top', duration: 500, ease:'inExpo'}), 600);
                            }}
                            href="#home"><label>A empresa</label></NavLink>
                        <NavLink
                            href="#news"><label>Notícias</label></NavLink>
                        <NavLink
                            href="#home">
                            <LogoImageStyled src={lileveLogo}/>
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                setTimeout( () => scrollToComponent(Procedures, { offset: -300, align: 'top', duration: 500, ease:'inExpo'}), 600);
                            }}
                            href="#home"><label>Procedimentos</label></NavLink>
                        <NavLink
                            href="#search-professionals"><label>Profissionais</label></NavLink>
                        {
                            !authenticated ?
                                <NavDrop title="Cadastre-se">
                                    <NavDropItem onClick={() => showLogin()}>Login</NavDropItem>
                                    <NavDropItem onClick={() => {
                                        setTimeout( () => scrollToComponent(Login, { offset: -300, align: 'top', duration: 500, ease:'inExpo'}), 600);
                                    }} href="#home">Registrar</NavDropItem>
                                </NavDrop>
                                :
                                <NavDrop title={loggedUser?.name?.split(' ')[0]}>
                                    <NavDropItem onClick={() => logout()}>Sair</NavDropItem>
                                </NavDrop>
                        }
                    </NavStyled>
                </Navbar.Collapse>
            </NavBarStyled>
        </>
    );
};

export default withRouter(TopMenu);

