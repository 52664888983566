import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {clearProcedureAction, procedureFindRequestAction, procedureListRequestAction} from "../ducks/procedure";
import {GlobalState} from "../ducks";
import {Pageable} from "../../interfaces/Pageable";

export const useProcedureList = () => {
    return useSelector((state: GlobalState) => state.procedure.procedureList);
};

export const useProcedure = () => {
    return useSelector((state: GlobalState) => state.procedure.procedure);
};

export const useFindProcedureHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((id: number) => {
        dispatch(procedureFindRequestAction(id))
    }, [dispatch]);
};

export const useFindProceduresHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((page: Pageable) => {
        dispatch(procedureListRequestAction(page));
    }, [dispatch]);
};

export const useClearProcedure = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearProcedureAction());
    }, [dispatch]);
};

export const useProcedureLoading = () => {
    return useSelector((state: GlobalState) => state.procedure.loading);
};
