import styled from "styled-components";
import { Input as InputAntd } from 'antd';

const FormInput = styled(InputAntd)`
    border-radius: 25px;
    font-size: 1rem;
    margin: 0.5rem 0;
    text-align: center;
`;

export default FormInput;
