import {Lead} from '../../interfaces/Lead';

export const CHECK_PROMOTIONAL_CODE_REQUEST = 'CHECK_PROMOTIONAL_CODE_REQUEST';
export const CHECK_PROMOTIONAL_CODE_SUCCESS = 'CHECK_PROMOTIONAL_CODE_SUCCESS';
export const CHECK_PROMOTIONAL_CODE_ERROR = 'CHECK_PROMOTIONAL_CODE_ERROR';
export const LEAD_SEND_REQUEST = 'LEAD_SEND_REQUEST';
export const LEAD_SEND_SUCCESS = 'LEAD_SEND_SUCCESS';
export const LEAD_SEND_ERROR = 'LEAD_SEND_ERROR';

export interface CheckPromotionalCodeRequestAction {
    type: typeof CHECK_PROMOTIONAL_CODE_REQUEST,
    payload: {
        code: string
    }
}

export interface CheckPromotionalCodeSuccessAction {
    type: typeof CHECK_PROMOTIONAL_CODE_SUCCESS,
}

export interface CheckPromotionalCodeErrorAction {
    type: typeof CHECK_PROMOTIONAL_CODE_ERROR,
}

export interface LeadSendRequestAction {
    type: typeof LEAD_SEND_REQUEST,
    payload: {
        lead: Lead
    }
}

export interface LeadSendSuccessAction {
    type: typeof LEAD_SEND_SUCCESS,
}

export interface LeadSendErrorAction {
    type: typeof LEAD_SEND_ERROR,
}

export const checkPromotionalCodeRequestAction = (code: string): CheckPromotionalCodeRequestAction => ({
    type: CHECK_PROMOTIONAL_CODE_REQUEST,
    payload: {
        code
    }
});

export const checkPromotionalCodeSuccessAction = (): CheckPromotionalCodeSuccessAction => ({
    type: CHECK_PROMOTIONAL_CODE_SUCCESS,
});

export const checkPromotionalCodeErrorAction = (): CheckPromotionalCodeErrorAction => ({
    type: CHECK_PROMOTIONAL_CODE_ERROR,
});


export const leadSendRequestAction = (lead: Lead): LeadSendRequestAction => ({
    type: LEAD_SEND_REQUEST,
    payload: {
        lead: lead
    }
});

export const leadSendSuccessAction = (): LeadSendSuccessAction => ({
    type: LEAD_SEND_SUCCESS,
});

export const leadSendErrorAction = (): LeadSendErrorAction => ({
    type: LEAD_SEND_ERROR,
});

export type Action =
    LeadSendRequestAction |
    LeadSendErrorAction |
    LeadSendSuccessAction |
    CheckPromotionalCodeRequestAction |
    CheckPromotionalCodeSuccessAction |
    CheckPromotionalCodeErrorAction;

export interface State {
    loading: boolean,
    successSend: boolean,
    successCode: boolean,
}

const INITIAL_STATE = {
    loading: false,
    successSend: false,
    successCode: false,
};

const leadReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case LEAD_SEND_REQUEST: {
            return {
                ...state,
                loading: true,
                successSend: false
            }
        }
        case LEAD_SEND_SUCCESS: {
            return {
                ...state,
                loading: false,
                successSend: true,
            }
        }
        case LEAD_SEND_ERROR: {
            return {
                ...state,
                loading: false,
                successSend: false,
            }
        }
        case CHECK_PROMOTIONAL_CODE_REQUEST: {
            return {
                ...state,
                loading: true,
                successCode: false
            }
        }
        case CHECK_PROMOTIONAL_CODE_SUCCESS: {
            return {
                ...state,
                loading: false,
                successCode: true,
            }
        }
        case CHECK_PROMOTIONAL_CODE_ERROR: {
            return {
                ...state,
                loading: false,
                successCode: false,
            }
        }
        default:
            return state;
    }
};

export default leadReducer;
