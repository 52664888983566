import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';
import Row from "../../components/Row";
import {LinkStyled} from "./styles";
import {useSendAppointmentDataSuccess} from "../../store/hooks/appointment";

const SuccessSentData = () => {
    const successSend = useSendAppointmentDataSuccess();
    const {resetForm, submitCount} = useFormikContext();

    useEffect(() => {
        if (successSend && submitCount > 0) {
            resetForm();
        }
    }, [successSend, resetForm, submitCount]);

    return (
        <>
            {successSend &&
                <Row>
                    <h6>Os dados foram enviados com sucesso.</h6>
                    <LinkStyled to={"/home"}>Clique aqui para voltar para a página inicial</LinkStyled>
                </Row>
            }
        </>
    )
};

export default SuccessSentData;
