import {Doctor} from "../../interfaces/Doctor";
import {QrCodeGenerateRequest} from "../../interfaces/QrCodeGenerateRequest";

export const PROFESSIONAL_LIST_REQUEST = 'PROFESSIONAL_LIST_REQUEST';
export const GENERATE_QRCODE_REQUEST = 'GENERATE_QRCODE_REQUEST';
export const GENERATE_QRCODE_SUCCESS = 'GENERATE_QRCODE_SUCCESS';
export const GENERATE_QRCODE_ERROR = 'GENERATE_QRCODE_ERROR';
export const PROFESSIONAL_LIST_SUCCESS = 'PROFESSIONAL_LIST_SUCCESS';
export const PROFESSIONAL_LIST_ERROR = 'PROFESSIONAL_LIST_ERROR';
export const CLEAR_QR_CODE = 'CLEAR_QR_CODE';
export const CLEAR_PROFESSIONAL_LIST = 'CLEAR_PROFESSIONAL_LIST';

export interface ProfessionalListRequestAction {
    type: typeof PROFESSIONAL_LIST_REQUEST,
    payload: {
        cityName: string
    }
}

export interface GenerateQrCodeRequestAction {
    type: typeof GENERATE_QRCODE_REQUEST,
    payload: {
        qrCodeData: QrCodeGenerateRequest
    }
}

export interface GenerateQrCodeSuccessAction {
    type: typeof GENERATE_QRCODE_SUCCESS,
    payload: {
        generatedQrCode: string
    }
}

export interface ClearProfessionalListAction {
    type: typeof CLEAR_PROFESSIONAL_LIST
}

export interface ClearQrCodeAction {
    type: typeof CLEAR_QR_CODE
}

export interface ProfessionalListSuccessAction {
    type: typeof PROFESSIONAL_LIST_SUCCESS,
    payload: {
        professionalList: Doctor[]
    }
}

export interface GenerateQrCodeErrorAction {
    type: typeof GENERATE_QRCODE_ERROR
}

export interface ProfessionalListErrorAction {
    type: typeof PROFESSIONAL_LIST_ERROR
}

export const generateQrCodeRequestAction = (data: QrCodeGenerateRequest): GenerateQrCodeRequestAction => ({
    type: GENERATE_QRCODE_REQUEST,
    payload: {
        qrCodeData: data
    }
});

export const professionalListRequestAction = (cityName: string): ProfessionalListRequestAction => ({
    type: PROFESSIONAL_LIST_REQUEST,
    payload: {
        cityName: cityName
    }
});

export const professionalListErrorAction = (): ProfessionalListErrorAction => ({
    type: PROFESSIONAL_LIST_ERROR
});

export const generateQrCodeErrorAction = (): GenerateQrCodeErrorAction => ({
    type: GENERATE_QRCODE_ERROR
});

export const clearProfessionalListAction = (): ClearProfessionalListAction => ({
    type: CLEAR_PROFESSIONAL_LIST
});

export const clearQrCodeAction = (): ClearQrCodeAction => ({
    type: CLEAR_QR_CODE
});

export const generateQrCodeSuccessAction = (generatedQrCode: string): GenerateQrCodeSuccessAction => ({
    type: GENERATE_QRCODE_SUCCESS,
    payload: {
        generatedQrCode
    }
});

export const professionalListSuccessAction = (professionalList: Doctor[]): ProfessionalListSuccessAction => ({
    type: PROFESSIONAL_LIST_SUCCESS,
    payload: {
        professionalList
    }
});

export type Action =
    ProfessionalListRequestAction
    | ProfessionalListSuccessAction
    | ClearProfessionalListAction
    | ClearQrCodeAction
    | GenerateQrCodeSuccessAction
    | GenerateQrCodeRequestAction
    | GenerateQrCodeErrorAction
    | ProfessionalListErrorAction;

export interface State {
    loading: boolean,
    professional: Doctor | null,
    professionalList: Doctor[],
    totalCount: number,
    qrCodeData: any,
    generatedQrCode: string | null
}

const INITIAL_STATE = {
    loading: false,
    professional: null,
    professionalList: [],
    totalCount: 0,
    qrCodeData: null,
    generatedQrCode: null
};

const procedureReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case GENERATE_QRCODE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case GENERATE_QRCODE_SUCCESS: {
            const {generatedQrCode} = action.payload;
            return {
                ...state,
                generatedQrCode,
                loading: false
            }
        }
        case PROFESSIONAL_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case PROFESSIONAL_LIST_SUCCESS: {
            const {professionalList} = action.payload;
            return {
                ...state,
                professionalList,
                loading: false
            }
        }
        case CLEAR_PROFESSIONAL_LIST: {
            return {
                ...state,
                professionalList: [],
                loading: false
            }
        }
        case CLEAR_QR_CODE: {
            return {
                ...state,
                generatedQrCode: null
            }
        }
        case GENERATE_QRCODE_ERROR:
        case PROFESSIONAL_LIST_ERROR: {
            return {
                ...state,
                loading: false
            }
        }
        default:
            return state;
    }
};

export default procedureReducer;
