import React from 'react';
import {ContentContainer, QrCodeImage, QrCodeTextContainer} from "./styles";

type Props = {
    qrCode: string
};

const QrCodeDialogContent = ({qrCode}: Props) => {
    const today = new Date();
    return (
        <ContentContainer>
            <QrCodeImage src={qrCode} alt="qr_code"/>
            <QrCodeTextContainer>
                <label>Para obter seu desconto</label>
                <label>salve este QR code e </label>
                <label>mostre ao médico na consulta.</label>
                <a href={qrCode} download={`qrcode_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.png`}>download</a>
                <label>Ele foi enviado também</label>
                <label>ao seu e-mail.</label>
            </QrCodeTextContainer>
        </ContentContainer>
    );
};

export default QrCodeDialogContent;
