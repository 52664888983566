import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {testimonialRequestAction} from "../ducks/testimonial";

export const useFindTestimonialsHandlers = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(testimonialRequestAction())
    }, [dispatch]);
};

export const useTestimonialLoading = () => {
    return useSelector((state: GlobalState) => state.testimonial.loading);
};

export const useTestimonialList = () => {
    return useSelector((state: GlobalState) => state.testimonial.testimonialList);
};
