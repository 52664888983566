import React from 'react';
import silicredLogo from "../../assets/images/silicred-logo.png";
import owlLogo from "assets/images/owll-logo.png";
import lileveLogo from "../../assets/images/lileve-logo.png";
import fb from "../../assets/images/fb.png";
import linkedin from "../../assets/images/in.png";
import ig from "../../assets/images/ig.png";
import styled from "styled-components";
import './styles.css';

type Props = {
};


const FooterLileveContainer = styled.div`
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    justify-content: space-evenly;
    background-color: #cacaca; 
    color: white;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

const FooterCopyrightContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    a {
        color: #6C5876;
    }
`;

const LileveLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1150px) {
        flex-direction: row;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;

const CenteredContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1150px) {
        flex-direction: row;
    }
    a {
        margin: 0.5rem;
    }
    p {
        text-align: center;
    }
`;

const Image = styled.img`
    max-height: 50px;
    max-width: 150px;
    margin: 0 20px;
`;

const SpaceBetweenContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    a {
        ${Image} {
            width: 100%;
            max-height: unset;
            margin: 0;
        }
    }
    @media (max-width: 1150px) {
        flex-direction: column;
        justify-content: space-between;
        a {
            text-align: center;
            ${Image} {
                width: 100%;
                max-height: unset;
                margin: 2rem 0;
                max-width: 150px;
            }
        }
    }
`;



const FooterContainer = (props: Props) => {
    return (
        <div>
            <FooterLileveContainer>
                <LileveLogoContainer>
                    <img src={lileveLogo} style={{maxWidth: '150px'}} alt="" />
                </LileveLogoContainer>
                <CenteredContentContainer>
                    <h3 style={{fontSize: '18px', maxWidth: '400px', textAlign: 'left'}}>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                        Essa é a oportunidade exclusiva de realizar o seu sonho! Conte conosco!
                        </p>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                        O tempo de mudar é agora!
                        </p>
                        <p style={{textAlign: 'left', padding: 0, margin: 0}}>
                        Seja livre! Seja LILEVE !
                        </p>
                    </h3>
                </CenteredContentContainer>
                <CenteredContentContainer>
                    {/*   <a href="https://www.linkedin.com/" target="noreferrer">
                        <img src={linkedin} alt=""/>
                        <FaLinkedin className="iconContainer" color="white"/>
                    </a>*/}
                    <a href="https://www.facebook.com/lilevecirurgiasplasticas" target="noreferrer">
                        <img src={fb} alt=""/>
                        {/*<FaFacebookSquare className="iconContainer" color="white"/>*/}
                    </a>
                    <a href="https://www.instagram.com/lileveplastica/" target="noreferrer">
                        <img src={ig} alt=""/>
                        {/*<FaInstagram className="iconContainer" color="white"/>*/}
                    </a>
                </CenteredContentContainer>
            </FooterLileveContainer>
            <FooterCopyrightContainer>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{textAlign: 'center'}}>
                        LILEVE -
                        Rua Saldanha Marino, 374 Sala 1006 - Centro - Florianópolis - SC
                    </p>
                    <p>
                        <span>Contato: <a href="tel:+554832091999">(48) 3209-1999</a></span>
                        <span style={{marginLeft: 10}}>CNPJ: 35.221.611/0001-44</span>

                    </p>
                    <p>
                        Design e Desenvolvimento por <a href="https://dtdevs.com" target="_blank">DTDevs LTDA</a>
                    </p>
                </div>
            </FooterCopyrightContainer>
        </div>
    );
};

export default FooterContainer;
