import React, {useCallback, useEffect, useState} from 'react';
import face from "../../../assets/images/face.png";
import breast from "../../../assets/images/breast.png";
import body from "../../../assets/images/body.png";
import notSurgical from "../../../assets/images/notSurgical.png";
import styled from "styled-components";
import {
    FlexContainerProcedures,
    ImageContainer,
    LinkStyled,
    ProcedureLayer, ProcedureLayerMobile,
    ProcedureTitle, TitleHiddenOnClickStyled,
    TitleHiddenOnHoverStyled
} from './styles';
import {useFindProceduresHandlers, useProcedureList} from "../../../store/hooks/procedure";
import {PAGE_SIZE} from "../../../constants";
import {Procedure, ProcedureType} from "../../../interfaces/Procedure";
import {Animated} from "react-animated-css";
import {withBreakpoints} from 'react-breakpoints'

type Props = {

};

const ResponsiveLayer = styled.h1`
`;

const ContentBlock = styled.div`
    box-shadow: 0px 0px 10px #afafaf;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const AnimatedStyled = styled(Animated)`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding-top: 15%;
`;

const AnimatedMobileStyled = styled(Animated)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 17%;
`;

const ProcedureSection = (props: any) => {
    const findProcedures = useFindProceduresHandlers();
    const procedures = useProcedureList();
    const [hoveringItem, setHoveringItem] = useState(0);
    const { breakpoints, currentBreakpoint } = props;
    const [clickedItem, setClickedItem] = useState('');

    const findProceduresPageable = useCallback(newPage => {
        return findProcedures({
            size: PAGE_SIZE,
            page: newPage
        });
    }, [findProcedures]);

    useEffect(() => {
        findProceduresPageable(0)
    }, [findProceduresPageable]);

    return (
        <ContentBlock>
            <FlexContainerProcedures>
                <ImageContainer
                    expanded={clickedItem === ProcedureType.FACIAL}
                    size={procedures?.filter( pr => pr.type === ProcedureType.FACIAL)?.length}
                    onMouseEnter={ () => setHoveringItem(1)}
                    onMouseLeave={ () => setHoveringItem(0)}>
                    <img  src={face} alt=""/>
                    {breakpoints[currentBreakpoint] > breakpoints.tablet ? (
                        <>
                            <ProcedureLayer>
                                <AnimatedStyled
                                    animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={hoveringItem === 1}>
                                    <ProcedureTitle>Face</ProcedureTitle>
                                    {
                                        procedures?.filter( pr => pr.type === ProcedureType.FACIAL)?.slice(0, 5).map( (p: Procedure, i: number) => (
                                            <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.FACIAL}`}><p>{p.name}</p></LinkStyled>
                                        )
                                        )
                                    }
                                    <LinkStyled to={`/procedure/0/${ProcedureType.FACIAL}`}><p>Outros</p></LinkStyled>
                                </AnimatedStyled>
                            </ProcedureLayer>
                        {hoveringItem !== 1 &&
                            <TitleHiddenOnHoverStyled>
                                <h1 style={{
                                    textShadow: '1px 1px 5px gray',
                                    fontWeight: 'bolder'}}>
                                    Face
                                </h1>
                            </TitleHiddenOnHoverStyled>
                        }
                        </>
                    ) : (
                        <>
                            <TitleHiddenOnClickStyled>
                                {clickedItem !== ProcedureType.FACIAL &&
                                    <ResponsiveLayer onClick={() => setClickedItem(clickedItem === ProcedureType.FACIAL ? '' : ProcedureType.FACIAL)}>
                                        Face
                                    </ResponsiveLayer>
                                }
                            </TitleHiddenOnClickStyled>
                            { clickedItem === ProcedureType.FACIAL &&
                                <ProcedureLayerMobile>
                                    <AnimatedMobileStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={clickedItem === ProcedureType.FACIAL}>
                                        <ProcedureTitle
                                            onClick={ () => setClickedItem(clickedItem === ProcedureType.FACIAL ? '' : ProcedureType.FACIAL)}>
                                            Face
                                        </ProcedureTitle>
                                            {
                                                procedures?.filter( pr => pr.type === ProcedureType.FACIAL)?.slice(0, 5).map( (p: Procedure, i: number) => (
                                                        <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.FACIAL}`}><p>{p.name}</p></LinkStyled>
                                                    )
                                                )
                                            }
                                        <LinkStyled to={`/procedure/0/${ProcedureType.FACIAL}`}><p>Outros</p></LinkStyled>
                                    </AnimatedMobileStyled>
                                </ProcedureLayerMobile>
                            }
                        </>
                    )}
                </ImageContainer>
                <ImageContainer
                    expanded={clickedItem === ProcedureType.BREAST}
                    size={procedures?.filter( pr => pr.type === ProcedureType.BREAST)?.length}
                    onMouseEnter={ () => setHoveringItem(2)}
                    onMouseLeave={ () => setHoveringItem(0)}>
                    <img  src={breast} alt=""/>
                    {breakpoints[currentBreakpoint] > breakpoints.tablet ?
                        <>
                            <ProcedureLayer>
                                <AnimatedStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={hoveringItem === 2}>
                                    <ProcedureTitle>Cirurgia Seios</ProcedureTitle>
                                    {
                                        procedures?.filter( pr => pr.type === ProcedureType.BREAST)?.map( (p: Procedure, i: number) => (
                                                <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.BREAST}`}><p>{p.name}</p></LinkStyled>
                                            )
                                        )
                                    }
                                    <LinkStyled to={`/procedure/0/${ProcedureType.BREAST}`}><p>Outros</p></LinkStyled>
                                </AnimatedStyled>
                            </ProcedureLayer>
                            { hoveringItem !== 2 &&
                            <TitleHiddenOnHoverStyled>
                                <h1  style={{
                                    textShadow: '1px 1px 5px gray',
                                    fontWeight: 'bolder'}}>
                                    Cirurgia Seios
                                </h1>
                            </TitleHiddenOnHoverStyled>
                            }
                        </>
                         :
                        (
                            <>
                                <TitleHiddenOnClickStyled>
                                    {clickedItem !== ProcedureType.BREAST &&
                                    <ResponsiveLayer onClick={() => setClickedItem(clickedItem === ProcedureType.BREAST ? '' : ProcedureType.BREAST)}>
                                        Cirurgia Seios
                                    </ResponsiveLayer>
                                    }
                                </TitleHiddenOnClickStyled>
                                { clickedItem === ProcedureType.BREAST &&
                                <ProcedureLayerMobile>
                                    <AnimatedMobileStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={clickedItem === ProcedureType.BREAST}>
                                        <ProcedureTitle
                                            onClick={ () => setClickedItem(clickedItem === ProcedureType.BREAST ? '' : ProcedureType.BREAST)}>
                                            Cirurgia Seios
                                        </ProcedureTitle>
                                        {
                                            procedures?.filter( pr => pr.type === ProcedureType.BREAST)?.slice(0, 5).map( (p: Procedure, i: number) => (
                                                    <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.BREAST}`}><p>{p.name}</p></LinkStyled>
                                                )
                                            )
                                        }
                                        <LinkStyled to={`/procedure/0/${ProcedureType.BREAST}`}><p>Outros</p></LinkStyled>
                                    </AnimatedMobileStyled>
                                </ProcedureLayerMobile>
                                }
                            </>
                        )
                    }
                </ImageContainer>
                <ImageContainer
                    expanded={clickedItem === ProcedureType.BODY}
                    size={procedures?.filter( pr => pr.type === ProcedureType.BODY)?.length}
                    onMouseEnter={ () => setHoveringItem(3)}
                    onMouseLeave={ () => setHoveringItem(0)}>
                    <img src={body} alt=""/>
                    {breakpoints[currentBreakpoint] > breakpoints.tablet ?
                        <>
                            <ProcedureLayer>
                                <AnimatedStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={hoveringItem === 3}>
                                    <ProcedureTitle>Corpo</ProcedureTitle>
                                    {
                                        procedures?.filter( pr => pr.type === ProcedureType.BODY)?.map( (p: Procedure, i: number) => (
                                                <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.BODY}`}><p>{p.name}</p></LinkStyled>
                                            )
                                        )
                                    }
                                    <LinkStyled to={`/procedure/0/${ProcedureType.BODY}`}><p>Outros</p></LinkStyled>
                                </AnimatedStyled>
                            </ProcedureLayer>
                            { hoveringItem !== 3 &&
                            <TitleHiddenOnHoverStyled>
                                <h1 style={{
                                    textShadow: '1px 1px 5px gray',
                                    fontWeight: 'bolder'}}>
                                    Corpo
                                </h1>
                            </TitleHiddenOnHoverStyled>
                            }
                        </>
                        :
                        (
                            <>
                                <TitleHiddenOnClickStyled>
                                    {clickedItem !== ProcedureType.BODY &&
                                    <ResponsiveLayer onClick={() => setClickedItem(clickedItem === ProcedureType.BODY ? '' : ProcedureType.BODY)}>
                                        Corpo
                                    </ResponsiveLayer>
                                    }
                                </TitleHiddenOnClickStyled>
                                { clickedItem === ProcedureType.BODY &&
                                <ProcedureLayerMobile>
                                    <AnimatedMobileStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={clickedItem === ProcedureType.BODY}>
                                        <ProcedureTitle
                                            onClick={ () => setClickedItem(clickedItem === ProcedureType.BODY ? '' : ProcedureType.BODY)}>
                                            Corpo
                                        </ProcedureTitle>
                                        {
                                            procedures?.filter( pr => pr.type === ProcedureType.BODY)?.slice(0, 5).map( (p: Procedure, i: number) => (
                                                    <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.BODY}`}><p>{p.name}</p></LinkStyled>
                                                )
                                            )
                                        }
                                        <LinkStyled to={`/procedure/0/${ProcedureType.BODY}`}><p>Outros</p></LinkStyled>
                                    </AnimatedMobileStyled>
                                </ProcedureLayerMobile>
                                }
                            </>
                        )
                    }

                </ImageContainer>
                <ImageContainer
                    expanded={clickedItem === ProcedureType.NON_SURGICAL}
                    size={procedures?.filter( pr => pr.type === ProcedureType.NON_SURGICAL)?.length}
                    onMouseEnter={ () => setHoveringItem(4)}
                    onMouseLeave={ () => setHoveringItem(0)}>
                    <img  src={notSurgical} alt=""/>
                    {breakpoints[currentBreakpoint] > breakpoints.tablet ?
                        <>
                            <ProcedureLayer>
                                <AnimatedStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={hoveringItem === 4}>
                                    <ProcedureTitle>Não cirurgico</ProcedureTitle>
                                    {
                                        procedures?.filter( pr => pr.type === ProcedureType.NON_SURGICAL)?.map( (p: Procedure, i: number) => (
                                                <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.NON_SURGICAL}`}><p>{p.name}</p></LinkStyled>
                                            )
                                        )
                                    }
                                    <LinkStyled to={`/procedure/0/${ProcedureType.NON_SURGICAL}`}><p>Outros</p></LinkStyled>
                                </AnimatedStyled>
                            </ProcedureLayer>
                            { hoveringItem !== 4 &&
                                <TitleHiddenOnHoverStyled>
                                    <h1 style={{
                                        textShadow: '1px 1px 5px gray',
                                        fontWeight: 'bolder'}}>
                                        Não cirurgico
                                    </h1>
                                </TitleHiddenOnHoverStyled>
                            }
                        </>
                        :
                            (
                                <>
                                    <TitleHiddenOnClickStyled>
                                        {clickedItem !== ProcedureType.NON_SURGICAL &&
                                        <ResponsiveLayer onClick={() => setClickedItem(clickedItem === ProcedureType.NON_SURGICAL ? '' : ProcedureType.NON_SURGICAL)}>
                                            Não cirurgico
                                        </ResponsiveLayer>
                                        }
                                    </TitleHiddenOnClickStyled>
                                    { clickedItem === ProcedureType.NON_SURGICAL &&
                                    <ProcedureLayerMobile>
                                        <AnimatedMobileStyled animationIn="slideInUp" animationOut="fadeOut" animationOutDuration={0} isVisible={clickedItem === ProcedureType.NON_SURGICAL}>
                                            <ProcedureTitle
                                                onClick={ () => setClickedItem(clickedItem === ProcedureType.NON_SURGICAL ? '' : ProcedureType.NON_SURGICAL)}>
                                                Não cirurgico
                                            </ProcedureTitle>
                                            {
                                                procedures?.filter( pr => pr.type === ProcedureType.NON_SURGICAL)?.slice(0, 5).map( (p: Procedure, i: number) => (
                                                        <LinkStyled key={i} to={`/procedure/${p.id}/${ProcedureType.NON_SURGICAL}`}><p>{p.name}</p></LinkStyled>
                                                    )
                                                )
                                            }
                                            <LinkStyled to={`/procedure/0/${ProcedureType.NON_SURGICAL}`}><p>Outros</p></LinkStyled>
                                        </AnimatedMobileStyled>
                                    </ProcedureLayerMobile>
                                    }
                                </>
                            )
                    }

                </ImageContainer>
            </FlexContainerProcedures>
        </ContentBlock>
    );
};

export default withBreakpoints(ProcedureSection);
