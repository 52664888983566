import * as Yup from 'yup';

const schema = Yup.object().shape({
    password: Yup
        .string()
        .trim()
        .required("O campo Nova Senha é obrigatório."),
    confirmation: Yup
        .string()
        .trim()
        .required("O campo Confirmação de Senha é obrigatório.")
        .oneOf([Yup.ref("password"), null], "As senhas precisam ser iguais.")
});

export default schema;
