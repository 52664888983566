import axios from 'axios';
import {showErrorToast} from './toast';
import SERVER_URL_API from "../environments";
import persistedStore from 'store';

const api = axios.create({
    baseURL: SERVER_URL_API
});

const requestHandler = (request: any) => {
    const token = persistedStore.store.getState().auth.token;
    request.headers['Access-Control-Allow-Origin'] = '*';
    if (token != null) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request
};

api.interceptors.request.use(
    request => requestHandler(request)
);

const errorResponseHandler = (error: any) => {
    // if has response show the error
    if (error.response) {
        if (error.response.status === 401) {
            showErrorToast('Usuário não autorizado!');
        }
    }
    throw JSON.parse(JSON.stringify(error.response));
};

api.interceptors.response.use(
    (response) => response,
    errorResponseHandler
);

export default api;
