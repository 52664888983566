import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {newsFindAllRequestAction, newsFindOneRequestAction} from "../ducks/news";

export const useNewsLoading = () => {
    return useSelector((state: GlobalState) => state.news.loading);
};

export const useNewsList = () => {
    return useSelector((state: GlobalState) => state.news.newsList);
};

export const useNews = () => {
    return useSelector((state: GlobalState) => state.news.news);
};

export const useNewsTotal = () => {
    return useSelector((state: GlobalState) => state.news.total);
};

export const useFindNewsHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((page: number, size: number) => {
        dispatch(newsFindAllRequestAction(page, size))
    }, [dispatch]);
};

export const useFindOneNewsHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((id: number) => {
        dispatch(newsFindOneRequestAction(id))
    }, [dispatch]);
};
