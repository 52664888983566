import styled from 'styled-components';

export const ImageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    img {
        width: 100%;
    }
`;

export const DetailsTabsContainer = styled.div`
    padding: 0.5rem;
`;

export const ProcedureDetailContainer = styled.div`
    padding: 1rem 8%;
    font-size: large;
`;