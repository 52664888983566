import * as Yup from 'yup';

const schema = Yup.object().shape({
    procedure: Yup
        .string()
        .trim()
        .required("O campo procedimento é obrigatório."),
    value: Yup
        .number()
        .required("O campo valor é obrigatório.")
});

export default schema;
