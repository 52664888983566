import { combineReducers } from 'redux';
import { State as AddressState } from './address';
import { State as ProcedureState } from './procedure';
import { State as TestimonialState } from './testimonial';
import { State as ProfessionalState } from './professional';
import { State as LeadState } from './lead';
import { State as NewsState } from './news';
import { State as AuthState } from './authentication';
import { State as AppointmentState } from './appointment';

import address from './address';
import auth from './authentication';
import procedure from './procedure';
import testimonial from './testimonial';
import professional from './professional';
import lead from './lead';
import news from './news';
import appointment from './appointment';


export interface GlobalState {
  auth: AuthState,
  address: AddressState,
  procedure: ProcedureState,
  testimonial: TestimonialState,
  professional: ProfessionalState,
  lead: LeadState,
  news: NewsState,
  appointment: AppointmentState
}

export default combineReducers({
  auth,
  address,
  procedure,
  testimonial,
  professional,
  lead,
  news,
  appointment
});

