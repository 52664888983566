import React, {useEffect} from 'react';
import TestimonialSlider from "../../TestimonialSlider";
import styled from "styled-components";
import {useFindTestimonialsHandlers, useTestimonialList} from "../../../store/hooks/testimonial";

type Props = {
};

const TestimonialSliderContainer = styled.div`
    background-color: #cbcad669;
    box-shadow: 0px 0px 10px #afafaf;
    display: flex; 
    padding: 2.5rem 0;
`;

const TestimonialSection = (props: Props) => {
    const findTestimonials = useFindTestimonialsHandlers();
    const testimonialsList = useTestimonialList();

    useEffect( () => {
        findTestimonials();
    }, [findTestimonials]);

    return (
        <>
            <TestimonialSliderContainer>
                <TestimonialSlider testimonials={testimonialsList}/>
            </TestimonialSliderContainer>
        </>
    );
};

export default TestimonialSection;
