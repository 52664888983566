import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {
    addressByCepRequestAction,
    clearAddressByCepAction,
    findCitiesByStateRequestAction,
    findStatesRequestAction
} from "../ducks/address";

export const useAddressByCep = () => {
    return useSelector((state: GlobalState) => state.address.address);
};

export const useStates = () => {
    return useSelector((state: GlobalState) => state.address.states);
};

export const useCities = () => {
    return useSelector((state: GlobalState) => state.address.cities);
};

export const useClearAddressByCep = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearAddressByCepAction())
    }, [dispatch]);};

export const useFindAddressByCepHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((cep: string) => {
        dispatch(addressByCepRequestAction(cep))
    }, [dispatch]);
};

export const useFindStatesHandlers = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(findStatesRequestAction())
    }, [dispatch]);
};

export const useFindCitiesByStateHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((stateId: number) => {
        dispatch(findCitiesByStateRequestAction(stateId))
    }, [dispatch]);
};

export const useAddressLoading = () => {
    return useSelector((state: GlobalState) => state.address.loading);
};
