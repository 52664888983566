import {Pageable} from "../../interfaces/Pageable";
import {Procedure} from "../../interfaces/Procedure";

export const PROCEDURE_LIST_REQUEST = 'PROCEDURE_LIST_REQUEST';
export const CLEAR_PROCEDURE = 'CLEAR_PROCEDURE';
export const PROCEDURE_LIST_SUCCESS = 'PROCEDURE_LIST_SUCCESS';
export const PROCEDURE_CREATE_REQUEST = 'PROCEDURE_CREATE_REQUEST';
export const PROCEDURE_DELETE_REQUEST = 'PROCEDURE_DELETE_REQUEST';
export const PROCEDURE_DELETE_SUCCESS = 'PROCEDURE_DELETE_SUCCESS';
export const PROCEDURE_SEARCH = 'PROCEDURE_SEARCH';
export const PROCEDURE_GET_DEFAULT_OPTIONS_REQUEST = 'PROCEDURE_GET_DEFAULT_OPTIONS_REQUEST';
export const PROCEDURE_GET_DEFAULT_OPTIONS = 'PROCEDURE_GET_DEFAULT_OPTIONS';
export const PROCEDURE_FIND_REQUEST = 'PROCEDURE_FIND_REQUEST';
export const PROCEDURE_FIND_SUCCESS = 'PROCEDURE_FIND_SUCCESS';
export const PROCEDURE_UPDATE_REQUEST = 'PROCEDURE_UPDATE_REQUEST';
export const PROCEDURE_UPDATE_SUCCESS = 'PROCEDURE_UPDATE_SUCCESS';


export interface ProcedureListRequestAction {
    type: typeof PROCEDURE_LIST_REQUEST,
    payload: {
        pageable: Pageable
    }
}

export interface ClearProcedureAction {
    type: typeof CLEAR_PROCEDURE
}

export interface ProcedureListSuccessAction {
    type: typeof PROCEDURE_LIST_SUCCESS,
    payload: {
        procedureList: Procedure[],
        totalCount: number
    }
}

export interface ProcedureUpdateSuccessAction {
    type: typeof PROCEDURE_UPDATE_SUCCESS,
    payload: {
        procedure: Procedure
    }
}

export interface ProcedureUpdateRequestAction {
    type: typeof PROCEDURE_UPDATE_REQUEST,
    payload: {
        procedure: Procedure
    }
}

export interface ProcedureCreateRequestAction {
    type: typeof PROCEDURE_CREATE_REQUEST,
    payload: {
        procedure: Procedure,
        goBack: () => void
    }
}

export interface ProcedureSearchAction {
    type: typeof PROCEDURE_SEARCH,
    payload: {
        searchParam: string
    }
}

// export interface ProcedureGetDefaultOptionsAction {
//     type: typeof PROCEDURE_GET_DEFAULT_OPTIONS,
//     payload: {
//         simulationOptions: SimulationOption[]
//     }
// }

export interface ProcedureGetDefaultOptionsRequestAction {
    type: typeof PROCEDURE_GET_DEFAULT_OPTIONS_REQUEST,
    payload: {
        procedure: Procedure
    }
}

export interface ProcedureFindRequestAction {
    type: typeof PROCEDURE_FIND_REQUEST,
    payload: {
        procedureId: number
    }
}

export interface ProcedureFindSuccessAction {
    type: typeof PROCEDURE_FIND_SUCCESS,
    payload: {
        procedure: Procedure
    }
}

export interface ProcedureDeleteRequestAction {
    type: typeof PROCEDURE_DELETE_REQUEST,
    payload: {
        procedureId: number
    }
}

export interface ProcedureDeleteSuccessAction {
    type: typeof PROCEDURE_DELETE_SUCCESS
}

export const procedureSearchAction = (searchParam: string): ProcedureSearchAction => ({
    type: PROCEDURE_SEARCH,
    payload: {
        searchParam: searchParam
    }
});

export const clearProcedureAction = (): ClearProcedureAction => ({
    type: CLEAR_PROCEDURE
});

export const procedureGetDefaultOptionsRequestAction = (procedure: Procedure): ProcedureGetDefaultOptionsRequestAction => ({
    type: PROCEDURE_GET_DEFAULT_OPTIONS_REQUEST,
    payload: {
        procedure: procedure
    }
});

export const procedureListRequestAction = (pageable: Pageable): ProcedureListRequestAction => ({
    type: PROCEDURE_LIST_REQUEST,
    payload: {
        pageable
    }
});

export const procedureCreateRequestAction = (procedure: Procedure, goBack: () => void): ProcedureCreateRequestAction => ({
    type: PROCEDURE_CREATE_REQUEST,
    payload: {
        procedure,
        goBack
    }
});

export const procedureListSuccessAction = (procedureList: Procedure[], totalCount: number): ProcedureListSuccessAction => ({
    type: PROCEDURE_LIST_SUCCESS,
    payload: {
        procedureList,
        totalCount
    }
});

export const procedureUpdateRequestAction = (procedure: Procedure): ProcedureUpdateRequestAction => ({
    type: PROCEDURE_UPDATE_REQUEST,
    payload: {
        procedure,
    }
});

export const procedureFindRequestAction = (procedureId: number): ProcedureFindRequestAction => ({
    type: PROCEDURE_FIND_REQUEST,
    payload: {
        procedureId
    }
});

export const procedureFindSuccessAction = (procedure: Procedure): ProcedureFindSuccessAction => ({
    type: PROCEDURE_FIND_SUCCESS,
    payload: {
        procedure
    }
});

export const procedureDeleteRequestAction = (procedureId: number): ProcedureDeleteRequestAction => ({
    type: PROCEDURE_DELETE_REQUEST,
    payload: {
        procedureId
    }
});

export const procedureDeleteSuccessAction = (): ProcedureDeleteSuccessAction => ({
    type: PROCEDURE_DELETE_SUCCESS
});

export type Action =
    ClearProcedureAction
    | ProcedureListRequestAction
    | ProcedureListSuccessAction
    | ProcedureSearchAction
    | ProcedureCreateRequestAction
    | ProcedureGetDefaultOptionsRequestAction
    | ProcedureDeleteRequestAction
    | ProcedureDeleteSuccessAction
    | ProcedureUpdateRequestAction
    | ProcedureUpdateSuccessAction
    | ProcedureFindRequestAction
    | ProcedureFindSuccessAction;

export interface State {
    loading: boolean,
    searchParam: string | null,
    procedure: Procedure | null,
    procedureList: Procedure[],
    totalCount: number,
    procedureId: number | null,
    deletedSuccess: boolean
}

const INITIAL_STATE = {
    loading: false,
    searchParam: null,
    procedure: null,
    simulationOptions: [],
    procedureList: [],
    totalCount: 0,
    procedureId: null,
    deletedSuccess: false
};

const procedureReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case PROCEDURE_DELETE_REQUEST:
        case PROCEDURE_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case PROCEDURE_LIST_SUCCESS: {
            const {procedureList, totalCount} = action.payload;
            return {
                ...state,
                procedureList,
                totalCount,
                deletedSuccess: false,
                loading: false
            }
        }
        case PROCEDURE_DELETE_SUCCESS: {
            return {
                ...state,
                deletedSuccess: true,
                loading: false
            }
        }
        case PROCEDURE_FIND_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case PROCEDURE_CREATE_REQUEST: {
            const {procedure} = action.payload;
            return {
                ...state,
                procedure,
                loading: true
            };
        }
        case PROCEDURE_UPDATE_REQUEST: {
            const {procedure} = action.payload;
            return {
                ...state,
                procedure,
                loading: true
            }
        }
        case PROCEDURE_FIND_SUCCESS: {
            const {procedure} = action.payload;
            return {
                ...state,
                procedure,
                loading: false
            };
        }
        case PROCEDURE_UPDATE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case CLEAR_PROCEDURE: {
            return {
                ...state,
                procedure: null
            }
        }
        case PROCEDURE_SEARCH:
            const {searchParam} = action.payload;
            return {
                ...state,
                searchParam
            };
        case PROCEDURE_GET_DEFAULT_OPTIONS_REQUEST:
            const {procedure} = action.payload;
            return {
                ...state,
                procedure
            };
        default:
            return state;
    }
};

export default procedureReducer;
