import * as Yup from 'yup';

const schema = Yup.object().shape({
    username: Yup.string()
        .max(255, 'O campo e-mail não pode possuir mais de 255 caracteres.')
        .required('O campo e-mail é de preenchimento obrigatório.'),
    password: Yup.string()
        .max(255, 'O campo senha não pode possuir mais de 255 caracteres.')
        .required('O campo senha é de preenchimento obrigatório.'),
});

export default schema;
