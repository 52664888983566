import React, {useEffect} from 'react';
import Row from '../../Row';
import {useSuccessSend} from '../../../store/hooks/lead';
import {useFormikContext} from 'formik';

const SuccessLead = () => {
    const successSend = useSuccessSend();
    const {resetForm, submitCount} = useFormikContext();

    useEffect(() => {
        if (successSend && submitCount > 0) {
            resetForm();
        }
    }, [successSend, resetForm, submitCount]);

    return (
        <>
            {successSend &&
            <Row>
                <h6>O formulário foi enviado com sucesso!</h6>
                <h6>Nossa equipe entrará em contato em breve.</h6>
            </Row>
            }
        </>
    )
};

export default SuccessLead;
