import styled from "styled-components";

export const TestimonialContainer = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    padding: 2% 10%;
    color: #6C5876;
    margin: 0 auto;
`;

export const TestimonialImageStyled = styled.img`
    height: 140px;
    border-radius: 8px;
`;
