import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
    small: () => `
        padding: 0;
        margin: 1rem;
`,
    medium: () => `
        padding: 1rem;
        margin: 2rem;

`,
    large: () => `
        padding: 2rem;
        margin: 3rem;
`
};

type Props = {
    modifiers?: string[] | string,
    spacing?: string
}

const Row = styled.div<Props>`
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;
export default Row;
