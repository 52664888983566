import React, {useEffect} from 'react';
import SectionTitle from "../../components/SectionTitle";
import ValidationSchema from "./ValidationSchema";
import Row from "../../components/Row";
import FormInput from "../../components/FormInput";
import {ErrorContainer, ErrorLabel} from "../../elements/Error";
import {ErrorMessage, Formik} from "formik";
import RoundButton from "../../components/RoundButton";
import {useAuthLoading, useChangePasswordRequest, useClearChangePasswordState} from "../../store/hooks/authentication";
import {AppContainer, InnerContainer} from "./styles";
import {withRouter} from "react-router";
import SuccessChangePassword from "./SuccessChangePassword";
import Loading from "../../components/Loading";


type Props = {
    history: any,
    location: any,
    match: {
        params: {
            hash: string
        }
    }
}

let initialValues: any = {
    password: '',
    confirmation: ''
};

const ResetPage = (props: Props) => {
    const changePassword = useChangePasswordRequest();
    const clearChangePasswordState = useClearChangePasswordState();
    const loading = useAuthLoading();

    const {hash: resetHash} = props.match.params;

    const handleSubmitChangePassword = ({password}: any) => {
        changePassword({password, resetHash});
    };

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

    useEffect(() => {
        clearChangePasswordState();
        return () => clearChangePasswordState();
    }, [clearChangePasswordState]);

    return (
        <AppContainer>
            { loading && <Loading/> }
            <InnerContainer>
                <SectionTitle title="Alteração de Senha"/>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitChangePassword}
                    validationSchema={ValidationSchema}
                    enableReinitialize={true}>
                    {({handleSubmit, values, handleChange, setFieldValue}) => (
                        <>
                            <Row modifiers="small"/>
                            <FormInput type="password" placeholder="nova senha" name="password" value={values.password}
                                       onChange={handleChange} maxLength={255}/>
                            <FormInput type="password" placeholder="confirme a nova senha" name="confirmation"
                                       value={values.confirmation} maxLength={255}
                                       onChange={handleChange}/>
                            <ErrorContainer>
                                <ErrorMessage name="password" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                <ErrorMessage name="confirmation" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                            </ErrorContainer>
                            <SuccessChangePassword/>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                    CONFIRMAR
                                </RoundButton>
                            </div>
                        </>
                    )}
                </Formik>
            </InnerContainer>
        </AppContainer>
    );
};

export default withRouter(ResetPage);
