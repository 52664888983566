import React, {useState} from 'react';
import './App.css';
import {HashRouter} from "react-router-dom";
import Routes from "./routes";
import {Provider} from "react-redux";
import persistedStore from './store';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './antd.css';
import pt_BR from 'antd/es/locale-provider/pt_BR';
import {LocaleProvider} from 'antd';
import LgpdWarn from "./components/LgpdWarn";

const App: React.FC = () => {
    const [lgpd, setLgpd] = useState(localStorage.getItem('lil-lgpd'));

    return (
        <LocaleProvider locale={pt_BR}>
            <Provider store={persistedStore.store}>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                />
                <HashRouter>
                    <Routes/>
                </HashRouter>
                {!lgpd &&
                    <LgpdWarn setLgpd={setLgpd} />
                }
            </Provider>
        </LocaleProvider>
        );
    };

export default App;
