import styled from 'styled-components';

export const InputStyled = styled.input`
    border-radius: 25px;
    border: 1px solid #cacaca;
    padding: 0.3rem;
    padding-left: 15px;
    min-width: 350px;
    margin-bottom: 0.5rem;
    :focus {
        outline: none;
    }
`;
