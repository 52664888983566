import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {
    clearAppointmentSuccessAction,
    loadAppointmentDataAction,
    sendAppointmentDataRequestAction
} from "../ducks/appointment";
import {Appointment} from "../../interfaces/Appointment";

export const useSendAppointmentDataRequestHandler = () => {
    const dispatch = useDispatch();
    return useCallback((appointment: Appointment) => {
        dispatch(sendAppointmentDataRequestAction(appointment))
    }, [dispatch]);
};

export const useLoadAppointmentData = () => {
    const dispatch = useDispatch();
    return useCallback((hash: string) => {
        dispatch(loadAppointmentDataAction(hash))
    }, [dispatch]);
};

export const useClearAppointmentDataSuccess = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearAppointmentSuccessAction())
    }, [dispatch]);
};

export const useSendAppointmentDataSuccess = () => {
    return useSelector((state: GlobalState) => state.appointment.sendAppointmentSuccess);
};

export const useAppointmentData = () => {
    return useSelector((state: GlobalState) => state.appointment.appointmentData);
};


