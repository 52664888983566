import styled from 'styled-components';
import { Animated } from 'react-animated-css';

type Props = {
    width?: string
}

export const AnimatedStyled = styled(Animated)`
    position: fixed;
    background: rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    width: 100%;
    height: 100%;
`;

export const DialogContainer = styled.div<Props>`
    position: relative;
    background: white;
    border-radius: 15px;
    padding: 3% 5%;
    ${props => props.width ? `
        width: ${props.width}%
    ` : ``}; 
    @media (max-width: 850px) {
        width: 80%;
        padding: 5% 5%;
    }
    @media (max-width: 1200px) and (min-width: 851px) {
        width: 50%;
        padding: 5% 5%;
    }
`;
