import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {showErrorToast} from '../../services/toast';
import {TESTIMONIAL_REQUEST, TestimonialRequestAction, testimonialSuccessAction} from "../ducks/testimonial";

export function* findTestimonialByKey(action: TestimonialRequestAction) {
    try {
        const findTestimonialApiCall = () => {
            return api.get(`api/testimonials`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    throw err;
                });
        };
        const testimonialList: any[] = yield call(findTestimonialApiCall);
        yield put(testimonialSuccessAction(testimonialList));
    } catch (err) {
        showErrorToast(err.data.detail || err.data.title);
    }
}

export default [
    takeLatest(TESTIMONIAL_REQUEST, findTestimonialByKey)
];
