import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useForgotPasswordSuccess, useHideLoginHandler} from "../../store/hooks/authentication";
import {showSuccessToast} from "../../services/toast";

const SuccessForgotPassword = () => {
    const successSend = useForgotPasswordSuccess();
    const {resetForm, submitCount} = useFormikContext();
    const hideLogin = useHideLoginHandler();

    useEffect(() => {
        if (successSend && submitCount > 0) {
            resetForm();
            showSuccessToast('Solicitação realizada com sucesso! Você receberá um e-mail com instruções.');
            hideLogin();
        }
    }, [successSend, resetForm, submitCount, hideLogin]);

    return null;
};

export default SuccessForgotPassword;
