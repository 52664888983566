import React, {useCallback, useEffect} from 'react';
import {ContentContainer, QrCodeTextContainer} from "./styles";
import FormInput from "../FormInput";
import Row from "../Row";
import {ErrorContainer, ErrorLabel} from "../../elements/Error";
import {ErrorMessage, Formik} from "formik";
import SuccessForgotPassword from "../LoginForm/SuccessForgotPassword";
import RoundButton from "../RoundButton";
import {RegisterLabelStyled} from "../LoginForm/styles";
import {
    useAuthLoading,
    useAuthUser,
    useCancelIncompleteAuth,
    useCompleteUserDataHandler
} from "../../store/hooks/authentication";
import {Option, Select} from "../Select";
import {useCities, useFindCitiesByStateHandlers, useFindStatesHandlers, useStates} from "../../store/hooks/address";
import {MaskUtils} from "../../utils/MasksUtil";
import Loading from "../Loading";

interface InitialValues {
    phone: string,
    promotional_code: string,
    state: string | number,
    city: string | number,
}

const CompleteDataDialog = () => {
    const completeUserDataRequest = useCompleteUserDataHandler();
    const cancelIncompleteAuth = useCancelIncompleteAuth();
    const loading = useAuthLoading();
    const user = useAuthUser();
    const states = useStates();
    const cities = useCities();
    const findCitiesByState = useFindCitiesByStateHandlers();
    const findStatesRequest = useFindStatesHandlers();

    let initialValues: InitialValues = {
        phone: '',
        state: 0,
        city: 0,
        promotional_code: ''
    };

    const handleSubmit = (values: any) => {
        completeUserDataRequest({...values, id: user?.id});
    };

    const findCitiesByStateHandler = useCallback((stateId: number) => {
        if (stateId && stateId !== 0) {
            findCitiesByState(stateId);
        }
    }, [findCitiesByState]);

    useEffect(() => {
        findStatesRequest();
    }, [findStatesRequest]);

    return (
        <>
            {loading && <Loading/>}
            <ContentContainer>
                <QrCodeTextContainer>
                    <label>Olá {user?.name}, para continuar precisamos que você complete seus dados de cadastro!</label>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}>
                        {({handleSubmit, values, handleChange, setFieldValue}) => (
                            <>
                                <Row modifiers="small"/>
                                {
                                    !user?.phone &&
                                    <FormInput type="text" placeholder="telefone" name="phone" value={values.phone}
                                               onChange={(event: any) => setFieldValue('phone', MaskUtils.formatCel(event.target.value))} maxLength={255}/>
                                }
                                {
                                    !user?.state &&
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption
                                        value={values.state}
                                        defaultValue={0}
                                        onChange={(event: any) => {
                                            if (event !== 0) {
                                                setFieldValue('state', states.find(st => st.id === event)?.name);
                                            } else {
                                                setFieldValue('state', event);
                                            }
                                            setFieldValue('city', 0);
                                            findCitiesByStateHandler(event);
                                        }}
                                    >
                                        <Option value={0}>UF</Option>
                                        {states?.map((st, i) => (
                                            <Option key={i} value={st?.id}>{st?.name}</Option>
                                        ))}
                                    </Select>
                                }
                                { !user?.city &&
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption
                                    value={values.city}
                                    onChange={(event: any) => {
                                        if (event !== 0) {
                                            setFieldValue('city', cities.find(st => st.id === event)?.name);
                                        } else {
                                            setFieldValue('city', event);
                                        }
                                    }}
                                >
                                    <Option value={0}>cidade</Option>
                                    {cities?.map((ci, i) => (
                                        <Option key={i} value={ci?.id}>{ci?.name}</Option>
                                    ))}
                                </Select>
                                }
                                {
                                    !user?.promotional_code &&
                                    <FormInput type="text" placeholder="código promocional" name="promotional_code" value={values.promotional_code}
                                               onChange={handleChange} maxLength={255}/>
                                }
                                <ErrorContainer>
                                    <ErrorMessage name="phone" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                    <ErrorMessage name="state" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                    <ErrorMessage name="city" render={msg => <ErrorLabel>{msg}</ErrorLabel>}/>
                                </ErrorContainer>
                                <SuccessForgotPassword/>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <RoundButton variant="primary" size="sm" onClick={handleSubmit} type="submit">
                                        COMPLETAR CADASTRO
                                    </RoundButton>
                                </div>
                                <RegisterLabelStyled onClick={() => {
                                    cancelIncompleteAuth()
                                }}>Continuar anonimamente</RegisterLabelStyled>
                            </>
                        )}
                    </Formik>
                </QrCodeTextContainer>
            </ContentContainer>
        </>
    );
};

export default CompleteDataDialog;
