import React, {useCallback, useEffect, useState} from 'react';
import SectionTitle from "../../components/SectionTitle";
import Paragraph from "../../components/Paragraph";
import styled from "styled-components";
import RoundButton from "../../components/RoundButton";
import {
    useClearProcedure,
    useFindProcedureHandlers,
    useFindProceduresHandlers,
    useProcedure,
    useProcedureList, useProcedureLoading
} from "../../store/hooks/procedure";
import {withRouter} from 'react-router';
import {DetailsTabsContainer, ProcedureDetailContainer} from "./styles";
import CarouselProcedures from "../../components/CarouselProcedures";
import {PAGE_SIZE} from "../../constants";
import {Animated} from "react-animated-css";
import {Faq, ProcedureType} from "../../interfaces/Procedure";
import Row from "../../components/Row";
import InfiniteScroll from 'react-infinite-scroller';
import FaqSearchField from "../../components/FaqSearchField";
import Loading from "../../components/Loading";

const OutterContainer = styled.div`
    margin-bottom: 7rem;
    @media (max-width: 1150px) {
        margin-bottom: 2rem;  
    }
`;

const ImageStyled = styled.img`
    max-height: 400px;
    border-radius: 10px;
    @media (max-width: 1150px) {
        margin: 0 auto;
        border-radius: 15px;
        width: 100%;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;

const ContentBlock = styled.div`
    background-color: #cbcad669;
    color: rgba(0,0,0,.5);
    display: flex;
    box-shadow: 0px 0px 10px #afafaf;
    margin-bottom: 1rem;
    padding: 1rem 8%;
    align-items: center;
    @media (max-width: 1150px) {
        flex-direction: column;
        padding: 1rem 8%;
    }
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    width: 100%;
`;

const OthersStyled = styled.h3`
    color: #6C5876;
`;

enum ActiveViewType {
    BASIC_INFO = 'BASIC_INFO',
    TREATMENT = 'TREATMENT',
    FAQ = 'FAQ'
}

type Props = {
    history: {
        goBack: () => void,
    },
    match: {
        params: {
            id: string,
            type: ProcedureType
        }
    }
}

const ProceduresPage = ({match, history}: Props) => {
    const [activeView, setActiveView] = useState(ActiveViewType.BASIC_INFO);
    const [limiter, setLimiter] = useState(2);
    const [faqSearchParam, setFaqSearchParam] = useState('');
    const [triggerAnimation, setTriggerAnimation] = useState(true);
    const {id: idParam} = match.params;
    const {type} = match.params;
    const findProcedureHandlers = useFindProcedureHandlers();
    const procedure = useProcedure();
    const loading = useProcedureLoading();
    const findProcedures = useFindProceduresHandlers();
    const procedures = useProcedureList();
    const clearProcedure = useClearProcedure();

    const findProceduresPageable = useCallback(newPage => {
        return findProcedures({
            size: PAGE_SIZE,
            page: newPage
        });
    }, [findProcedures]);

    const changeView = useCallback((view: ActiveViewType) => {
        setActiveView(view);
        setTriggerAnimation(false);
        setTimeout(() => setTriggerAnimation(true));
    }, [setActiveView, setTriggerAnimation]);

    const checkFaqSimilarity = (faq: Faq, parameter: string) => {
        return faq?.question?.toUpperCase()?.indexOf(parameter.toUpperCase()) !== -1 || faq?.answer?.toUpperCase().indexOf(parameter.toUpperCase()) !== -1;
    };

    useEffect(() => {
        findProceduresPageable(0)
    }, [findProceduresPageable, idParam]);

    useEffect(() => {
        findProcedureHandlers(parseInt(idParam));
    }, [findProcedureHandlers, idParam]);

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

    useEffect(() => {
        return () => {
            clearProcedure();
        };
    }, [clearProcedure]);

    return (
        <div style={{marginTop: '4rem'}}>
            { loading && <Loading/>}
            {procedure &&
            <OutterContainer>
                <SectionTitle title={procedure.name}/>
                <ContentBlock>
                    <ImageStyled src={`data:${procedure?.imageContentType};base64,${procedure?.image}`} alt=""/>
                    <TextBlock>
                        <Paragraph color="#6C5876" modifiers={["center", "xlarge"]}>
                            {procedure.shortDesc}
                        </Paragraph>
                        <Paragraph color="#6C5876" modifiers={["center", "large"]}>
                            {procedure.fullDesc}
                        </Paragraph>
                    </TextBlock>
                </ContentBlock>
                <ButtonsContainer>
                    <RoundButton
                        bgcolor={activeView === ActiveViewType.BASIC_INFO ? null : "#AFACB4"}
                        variant="primary"
                        size="sm"
                        onClick={() => changeView(ActiveViewType.BASIC_INFO)}>
                        INFORMAÇÕES BÁSICAS
                    </RoundButton>
                    <RoundButton
                        bgcolor={activeView === ActiveViewType.TREATMENT ? null : "#AFACB4"}
                        variant="primary"
                        size="sm"
                        onClick={() => changeView(ActiveViewType.TREATMENT)}>
                        SOBRE O TRATAMENTO
                    </RoundButton>
                    <RoundButton
                        bgcolor={activeView === ActiveViewType.FAQ ? null : "#AFACB4"}
                        variant="primary"
                        size="sm"
                        onClick={() => changeView(ActiveViewType.FAQ)}>
                        PERGUNTAS FREQUENTES
                    </RoundButton>
                </ButtonsContainer>
                <DetailsTabsContainer>
                    {
                        {
                            'BASIC_INFO':
                                <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0}
                                          isVisible={triggerAnimation}>
                                    <ProcedureDetailContainer>
                                        <div>
                                            <Paragraph color="#6C5876"
                                                       modifiers={['uppercase', 'bold', 'center']}>{procedure?.basicInfo?.title}</Paragraph>
                                            <Paragraph color="#6C5876"
                                                       modifiers={['center']}>{procedure?.basicInfo?.desc}</Paragraph>
                                        </div>
                                    </ProcedureDetailContainer>
                                </Animated>,
                            'TREATMENT':
                                <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0}
                                          isVisible={triggerAnimation}>
                                    <ProcedureDetailContainer>
                                        <Paragraph color="#6C5876" modifiers="justify">
                                            {procedure.treatment}
                                        </Paragraph>
                                    </ProcedureDetailContainer>
                                </Animated>,
                            'FAQ':
                                <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0}
                                          isVisible={triggerAnimation}>
                                    <ProcedureDetailContainer>
                                        <FaqSearchField value={faqSearchParam} handleChange={setFaqSearchParam}/>
                                            <div>
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    threshold={0}
                                                    loadMore={ () => setTimeout(() => setLimiter(limiter + 2), 1000)}
                                                    hasMore={procedure && procedure.faqs && limiter <= procedure?.faqs?.length}
                                                    loader={<div className="loader" key={0}>Carregando mais ...</div>}>
                                            <div>{procedure?.faqs?.filter( (fa) => checkFaqSimilarity(fa, faqSearchParam)).slice(0, faqSearchParam ? procedure?.faqs?.length : limiter).map((f: any) => (
                                                <div key={f?.id}>
                                                    <Paragraph modifiers={['uppercase', 'bold']}>P
                                                        - {f.question}</Paragraph>
                                                    <Paragraph color="#6C5876">R - {f.answer}</Paragraph>
                                                </div>
                                            ))}</div></InfiniteScroll>
                                        </div>
                                    </ProcedureDetailContainer>
                                </Animated>,
                        }[activeView]
                    }
                </DetailsTabsContainer>
            </OutterContainer>
            }
            {
                !procedure && <Row modifiers="medium"/>
            }
            {
                procedures.filter(p => p.type === type && p.id !== procedure?.id)?.length > 0 &&
                <>
                    <OthersStyled>Veja outros procedimentos relacionados:</OthersStyled>
                    <CarouselProcedures proceduresParam={procedures} filter={type} mainProcedure={procedure}/>
                </>
            }
        </div>
    );
};

export default withRouter(ProceduresPage);
