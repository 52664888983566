import React, {useMemo} from 'react';
import RoundButton from "../../RoundButton";
import {BannerText, ButtonFooter, TextBlock} from "../styles";
import {Link} from "react-router-dom";
import styled from "styled-components";
import company from "../../../assets/images/empresa3.jpg";
import {Tooltip} from "antd";
import {Login} from "../../../pages/HomePage";
import useBreakpoint from "../../../store/hooks/breakpoint";
const scrollToComponent = require('react-scroll-to-component');

type Props = {
    color?: string;
    align?: string;
    middlewhite?: boolean;
    width?: string;
    centeredContent?: boolean;
    verticalCenter?: boolean;
};

const ContentBlock = styled.div`
    background-color: #cbcad669;
    display: flex ;
    box-shadow: 0px 0px 10px #afafaf;
    position: relative;
    padding: 0 10%;
    @media (max-width: 1150px) {
        flex-direction: column;    
        padding: 0;
    }
`;

const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    @media (max-width: 900px) {
      flex-direction: column;
    }
`;

const BannerTitle = styled.h3<Props>`
    padding: 0 5%;
    font-size: 60px;
    text-align: left;
    text-align: ${props => props.align};
    line-height: 1.32;
    letter-spacing: 2px;
    font-family: OpenSans-Semibold, "Open Sans Semibold", sans-serif;
    font-weight: 600;
    font-style: normal;
    ${props => !props.middlewhite ? `color: ${props.color ? props.color : '#7e778c'}` : ``};
    ${props => props.middlewhite ?
    `
        color: white;
        text-shadow: 2px 2px #7e778c;
        span:first-child {
            color: ${props.color ? props.color : '#7e778c'};
            text-shadow: unset;

        }
        span:last-child {
            color: ${props.color ? props.color : '#7e778c'};
            text-shadow: unset;
        }
    ` : ' '}
    @media (max-width: 1150px) {
        font-size: large !important;
        font-weight: bolder;
        letter-spacing: unset;
        text-align: ${props => props.align ? props.align : 'center'};
    }  

`;

const CompanySection = (props: Props) => {
    const breakpoint = useBreakpoint()
    const iframeSize = useMemo(() => {
        if (breakpoint === 'xs') {
            return {width: 200, height: 125};
        }
        if (breakpoint === 'sm') {
            return {width: 300, height: 185};
        }
        if (breakpoint === 'md') {
            return {width: 400, height: 235};
        }
        return {width: 545, height: 300};
    }, [breakpoint]);
    return (
        <div>
            {/*  <ContentBlock>
                <TextBlock background={company}>
                    <div>
                        <BannerTitle align="left" color="#6C5876">
                            <span>Agora </span><span>você </span><span>pode!</span>
                        </BannerTitle>
                        <BannerText align="left" color="rgb(87, 87, 87)">
                            Realizamos o sonho de sua
                        </BannerText>
                        <BannerText align="left" color="rgb(87, 87, 87)">Plástica ou Silicone pelo melhor valor!</BannerText>
                    </div>
                    <div style={{ marginTop: '3%'}}>
                        <BannerTitle color="white" align="center">Empodere-se!</BannerTitle>
                        <BannerText color="rgb(87, 87, 87)" align="center">Agende sua avaliação agora.</BannerText>
                    </div>
                    <ButtonFooter>
                        <a href={`https://api.whatsapp.com/send?phone=+554896568685`} target="noreferrer">
                            <RoundButton variant="primary" alter={1} size="sm">
                                agende sua avaliação
                            </RoundButton>
                        </a>
                    </ButtonFooter>
                    <h4>Você não está sozinha!</h4>
                    <h4>Uma de nossas consultoras irá ajudá-la!</h4>
                </TextBlock>
            </ContentBlock>*/}
            <TextBlock background={company}>
                <Line>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px'}}>
                        <h3 style={{color: '#ADA2B2', fontSize: '28px', fontWeight: 'bolder'}}>A Lileve</h3>
                        <h4 style={{color: 'rgb(87, 87, 87)', fontSize: '20px', fontWeight: 'bolder'}}>Surgiu de um inconformismo de que só pessoal de alto poder aquisitivo tinham acesso a cirurgia plástica. Esta é a Lileve. Veja como nós podemos conectar você a todas as soluções para realização de uma cirurgia plástica.</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <iframe width={iframeSize.width} height={iframeSize.height} src="https://www.youtube.com/embed/G4ILdZW9L9s"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        <div>
{/*
                            <a href={`https://api.whatsapp.com/send?phone=+554896568685`} target="noreferrer">
*/}
                            <div onClick={() => {
                                setTimeout( () => scrollToComponent(Login, { offset: -300, align: 'top', duration: 500, ease:'inExpo'}), 600);
                            }}>
                                <RoundButton variant="primary" alter={1} size="sm">
                                    Cadastre-se
                                </RoundButton>
                            </div>
                        </div>
                    </div>
                </Line>
            </TextBlock>
        </div>
    );
};

export default CompanySection;
