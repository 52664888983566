import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react'
import {GlobalState} from "../ducks";
import {checkPromotionalCodeRequestAction, leadSendRequestAction} from '../ducks/lead';
import {Lead} from '../../interfaces/Lead';

export const useLeadLoading = () => {
    return useSelector((state: GlobalState) => state.lead.loading);
};

export const useSuccessSend = () => {
    return useSelector((state: GlobalState) => state.lead.successSend);
};

export const useCreateLeadHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((lead: Lead) => {
        dispatch(leadSendRequestAction(lead))
    }, [dispatch]);
};

export const useSuccessCheckPromoCode = () => {
    return useSelector((state: GlobalState) => state.lead.successCode);
};

export const useCheckPromoCodeHandlers = () => {
    const dispatch = useDispatch();
    return useCallback((code: string) => {
        dispatch(checkPromotionalCodeRequestAction(code))
    }, [dispatch]);
};
