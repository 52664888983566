import React, {useEffect} from 'react';
import {AnimatedStyled, DialogContainer} from "./styles";

type Props = {
    children?: any,
    setHidden?: any,
    width?: string
};

const Dialog = (props: Props) => {
    const root = document.getElementById('root');

    useEffect( () => {
        root?.classList.add('locked');
        return () => {
            root?.classList.remove('locked');
        };
    }, [root]);

    return (
        <AnimatedStyled animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0} isVisible={true}>
            <DialogContainer width={props.width}>
                <div style={{position: 'absolute', top: '0', right: '5%', fontSize: 'xx-large', cursor: 'pointer'}} onClick={props.setHidden}>x</div>
                {props.children}
            </DialogContainer>
        </AnimatedStyled>
    );
};

export default Dialog;
