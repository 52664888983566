import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
`;

export const WhatsAppImage = styled.img`
    max-width: 60px;
`;

export const Label = styled.span`
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
`;
