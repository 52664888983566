import React from 'react';
import {AnchorPhone, ContactContainer, FaPhoneStyled, IconAnchorStyled, LabelPhone, PhoneContainer} from "./styles";
import {FaFacebookSquare, FaInstagram, FaLinkedin, FaWhatsapp, FaMapMarker} from "react-icons/fa";
import {Doctor} from "../../interfaces/Doctor";

type Props = {
    doctor?: Doctor
}

const ProfessionalContactContainer = (props: Props) => {
    return (
        <ContactContainer>
            <div style={{display: 'flex'}}>
                <IconAnchorStyled href={`https://www.google.com.br/maps/place/${props?.doctor?.address?.street} ${props?.doctor?.address?.number} ${props?.doctor?.address?.city} ${props?.doctor?.address?.state} ${props?.doctor?.address?.postal_code}`} target="noreferrer">
                    <FaMapMarker className="iconContainer" color="white"/>
                </IconAnchorStyled>
                { props?.doctor?.whatsapp &&
                <IconAnchorStyled href={`https://api.whatsapp.com/send?phone=${props.doctor?.whatsapp}`} target="noreferrer">
                    <FaWhatsapp className="iconContainer" color="white"/>
                </IconAnchorStyled>
                }
                {props?.doctor?.linkedin &&
                <IconAnchorStyled href={props.doctor?.linkedin} target="noreferrer">
                    <FaLinkedin className="iconContainer" color="white"/>
                </IconAnchorStyled>
                }
                {props?.doctor?.facebook &&
                <IconAnchorStyled href={props.doctor?.facebook} target="noreferrer">
                    <FaFacebookSquare className="iconContainer" color="white"/>
                </IconAnchorStyled>
                }
                {props?.doctor?.instagram &&
                <IconAnchorStyled href={props.doctor?.instagram} target="noreferrer">
                    <FaInstagram className="iconContainer" color="white"/>
                </IconAnchorStyled>
                }
            </div>
            {props?.doctor?.phone &&
            <AnchorPhone href={`tel:${props.doctor?.phone}`} target="noreferrer">
                <PhoneContainer>
                    <LabelPhone>
                        {props.doctor?.phone}
                    </LabelPhone>
                    <FaPhoneStyled className="iconContainer-phone" color="gray"/>
                </PhoneContainer>
            </AnchorPhone>
            }
        </ContactContainer>
    );
};

export default ProfessionalContactContainer;
