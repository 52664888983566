import React from 'react';
import {Container, IconGoogle, Label} from './styles';

const GoogleButton = (props: any) => {
    return (
        <Container {...props}>
            <IconGoogle/>
            <Label>Entrar com o Google</Label>
        </Container>
    )
};

export default GoogleButton;
