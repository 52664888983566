import {call, put, takeLatest} from 'redux-saga/effects';
import api from '../../services/api';
import {
    LOAD_APPOINTMENT_DATA,
    LoadAppointmentDataAction,
    loadAppointmentDataSuccessAction,
    SEND_APPOINTMENT_DATA_REQUEST,
    SendAppointmentDataRequestAction,
    sendAppointmentDataSuccessAction
} from "../ducks/appointment";
import {showErrorToast} from '../../services/toast';

export function* sendAppointmentData(action: SendAppointmentDataRequestAction) {
    try {
        const {appointment} = action.payload;
        const req = {
            procedure: appointment.procedure,
            procedureDate: new Date(),
            value: appointment.value,
            hash: appointment.hash,
            observation: appointment.observation
        };
        const sendAppointmentDataApiCall = () => {
            return api.post(`api/appointment-data`, req)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        yield call(sendAppointmentDataApiCall);
        yield put(sendAppointmentDataSuccessAction());
    } catch (err) {
        showErrorToast("Este QRcode já foi utilizado ou expirou.");
    }
}

export function* loadAppointmentData(action: LoadAppointmentDataAction) {
    try {
        const {hash} = action.payload;
        const loadAppointmentDataApiCall = () => {
            return api.get(`api/qrcode/appointment-data/${hash}`)
                .then(response => {
                    if (response.status === 204) {
                        return response.data;
                    } else {
                        return response.data;
                    }
                })
                .catch(err => {
                    throw err;
                });
        };
        const response = yield call(loadAppointmentDataApiCall);
        yield put(loadAppointmentDataSuccessAction(response));
    } catch (err) {
        console.log(err);
        // yield put(authFailedAction(err));
    }
}

export default [
    takeLatest(SEND_APPOINTMENT_DATA_REQUEST, sendAppointmentData),
    takeLatest(LOAD_APPOINTMENT_DATA, loadAppointmentData)
];
