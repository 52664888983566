import React, {useEffect, useState} from 'react';
import SectionTitle from "../../components/SectionTitle";
import {
    AppContainer,
    ButtonContainer,
    InnerContainer,
    NewsContainer,
    NewsImageStyled,
    NewsPostContainer,
    NewsWrapper,
    TextContainer
} from "./styles";
import {withRouter} from "react-router";
import Loading from "../../components/Loading";
import Pagination from "react-js-pagination";
import RoundButton from "../../components/RoundButton";
import {useFindNewsHandlers, useNewsList, useNewsLoading, useNewsTotal} from "../../store/hooks/news";
import {PAGE_SIZE} from "../../constants";
import {Link} from "react-router-dom";
import Row from "../../components/Row";
import useFetchPag from "../../hooks/useFetchPag";
import {findNewsPaginated} from "../../services/news";

type Props = {
    history: any,
    location: any,
    match: {
        params: {
            id: string
        }
    }
}

const NewsPage = ({match, history}: Props) => {
    const [activePage, setActivePage] = useState(1);
    const loading = useNewsLoading();
    const findAllNewsPaginated = useFindNewsHandlers();
/*
    const newsList = useNewsList();
*/
    const total = useNewsTotal();
    const [
        {
            isLoading: isFetching,
            data: newsList,
            totalItems,
            currentPage: page,
            pageSize,
        },
        fetchNews,
    ] = useFetchPag(findNewsPaginated, '', true, [], () => {}, () => {}, true);

    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber);
    };

    useEffect(() => {
        setTimeout( () => window.scrollTo(0, 50), 100);
    }, []);

/*    useEffect(() => {
        findAllNewsPaginated(activePage - 1, PAGE_SIZE);
    }, [findAllNewsPaginated, activePage]);*/

    return (
        <AppContainer>
            { loading && <Loading/> }
            <InnerContainer>
                <SectionTitle title="Notícias"/>
            </InnerContainer>
                    <>
                        <NewsContainer>
                            {
                                newsList?.map( (news: any, i: any) =>
                                    <NewsWrapper key={i}>
                                        <NewsImageStyled src={`data:image/png;base64,${news?.image_preview}`}/>
                                        <NewsPostContainer>
                                            <TextContainer>
                                                <h4>{news.title}</h4>
                                                <h5>{news.author}</h5>
                                                <label>{news.preview}</label>
                                            </TextContainer>
                                            <ButtonContainer>
                                                <Link to={`/news/${news.id}`}>
                                                    <RoundButton
                                                        variant="primary"
                                                        size="sm"
                                                        type="submit">
                                                        SAIBA MAIS
                                                    </RoundButton>
                                                </Link>
                                            </ButtonContainer>
                                        </NewsPostContainer>
                                    </NewsWrapper>
                                )
                            }
                            {
                                newsList?.length === 0 &&
                                <>
                                    <h3>Não há notícias no momento.</h3>
                                    <Row modifiers="small"/>
                                </>

                            }
                        </NewsContainer>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={PAGE_SIZE}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </>
        </AppContainer>
    );
};

export default withRouter(NewsPage);
