import React, {useEffect, useState} from 'react';
import {LinkStyled, ServiceSelectionStyle} from './styles';
import Slider from 'react-slick';
import {Procedure, ProcedureType} from '../../interfaces/Procedure';

type Props = {
    proceduresParam: Procedure[],
    filter: ProcedureType,
    mainProcedure?: Procedure | null
}

export const settingsSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const CarouselProcedures = ({proceduresParam, filter, mainProcedure}: Props) => {
    const [procedures, setProcedures] = useState<Procedure[]>([]);

    useEffect( () => {
        setProcedures(proceduresParam.filter(p => p.type === filter && p.id !== mainProcedure?.id));
    }, [proceduresParam, filter, mainProcedure]);

    return (
        <ServiceSelectionStyle>
            <Slider {...settingsSlider}>
                {procedures.map((procedure) =>
                    <LinkStyled key={procedure.id} to={`/procedure/${procedure.id}/${filter}`}>
                        <div>
                            <div>
                                <img style={{width: '150px', height: '100px', borderRadius: '15px', margin: '0 auto', boxShadow: '1px 1px 8px #cacaca'}} src={`data:${procedure.imageContentType};base64,${procedure.image}`} alt=""/>
                                <label style={{width: '100%', textAlign: 'center'}}>{procedure.name}</label>
                            </div>
                        </div>
                    </LinkStyled>
                )}
            </Slider>
        </ServiceSelectionStyle>
    )
};

export default CarouselProcedures;
