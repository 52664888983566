import React, {useEffect} from 'react';
import ProfessionalContactContainer from "../ProfessionalContactContainer";
import {Doctor} from "../../interfaces/Doctor";
import {
    FaQrCodeStyled, LogoImageStyled,
    NameLabel,
    ProfessionalData,
    ProfessionalImage,
    ProfessionalItemContainer,
    QrCodeContainer, QrCodeFlex, RegisterLabelStyled, SpaceBetweenContainer,
    SpecialtyLabel
} from "./styles";
import {useClearQrCode, useGenerateQrCodeHandlers} from "../../store/hooks/professional";
import {useAuthUser} from "../../store/hooks/authentication";
import RoundButton from "../RoundButton";
import FormInput from "../FormInput";
import lileveLogo from "../../assets/images/lileve-logo-gray-sm.png";
import {Modal} from 'antd';
import './styles.css';

type Props = {
    professional?: Doctor
};

const ProfessionalItem = ({professional}: Props) => {
    const [open, setOpen] = React.useState(false);
    const [promotionalCode, setPromotionalCode] = React.useState('');
    const generateQrCode = useGenerateQrCodeHandlers();
    const loggedUser = useAuthUser();
    const clearQrCode = useClearQrCode();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (code: string) => {
        setPromotionalCode(code);
    };

    useEffect( () => {
        return () => {
            clearQrCode();
        }
    }, [clearQrCode]);

    return (
        <ProfessionalItemContainer>
            {
                professional?.image && <ProfessionalImage src={`data:image/jpeg;base64,${professional?.image}`}/>
            }
            <ProfessionalContactContainer doctor={professional}/>
            <SpaceBetweenContainer>
                <ProfessionalData>
                    <NameLabel>{professional?.name}</NameLabel>
                    <SpecialtyLabel>{professional?.specialty}</SpecialtyLabel>
                    <p>{professional?.desc}</p>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {professional?.services}
                    </div>
                    {/*<div>*/}
                    {/*    {professional?.qualifications?.map((q: any, i: number) => (*/}
                    {/*        <p key={i}>- {q.name}</p>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </ProfessionalData>
                <QrCodeFlex>
                    <QrCodeContainer
                        onClick={ () =>
                        {
                            handleClickOpen();

                        }}>
                        <FaQrCodeStyled/>
                        <label>Gerar QR code</label>
                        <label>para desconto</label>
                    </QrCodeContainer>
                </QrCodeFlex>
            </SpaceBetweenContainer>


            <Modal
                title="Vertically centered modal dialog"
                centered
                visible={open}
                footer={null}
                className="override-modal"
            >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '2rem'}}>
                    <LogoImageStyled src={lileveLogo}/>
                    <h5 style={{textAlign: 'center', marginTop: '10px'}}>
                        Gerar QR code para consulta
                    </h5>
                    <h6 style={{textAlign: 'center'}}>
                        Caso você possua, digite seu código promocional.
                    </h6>
                    <FormInput type="text" placeholder="código promocional" name="promotionalCode"
                               onChange={ (e: any) => handleChange(e.target.value)}
                               maxLength={255}/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <RoundButton variant="primary" size="sm"
                                     onClick={ () => {
                                         generateQrCode({userId: loggedUser?.id, profId: professional?.id?.toString(), promotionalCode: promotionalCode});
                                         handleClose();
                                     }}>
                            GERAR
                        </RoundButton>
                    </div>
                    <RegisterLabelStyled style={{textAlign: 'center'}} onClick={handleClose}>Voltar</RegisterLabelStyled>
                </div>
            </Modal>
        </ProfessionalItemContainer>
    );
};

export default ProfessionalItem;
