import styled from 'styled-components';
import {Link} from "react-router-dom";

export const AppContainer = styled.div`
    text-align: center;
    margin: 4rem 0 3rem;
    display: flex;
    justify-content: center;
`;

export const InnerContainer = styled.div`
    width: 40%;
    @media (max-width: 650px) {
        width: unset;
    }
`;

export const LinkStyled = styled(Link)`
    color: #7e778b;
    :hover {
        color: #7e778b;
    }
`;

