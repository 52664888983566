import * as Yup from 'yup';
import {MaskUtils} from '../../../utils/MasksUtil';

const schema = Yup.object().shape({
    name: Yup.string()
        .max(255, 'O campo nome não pode possuir mais de 255 caracteres.')
        .required('O campo nome é de preenchimento obrigatório.'),
    city: Yup.string()
        .max(255, 'O campo cidade não pode possuir mais de 255 caracteres.')
        .required('O campo cidade é de preenchimento obrigatório.')
        .test('city', 'O campo cidade é de preenchimento obrigatório.', val => {
            return +val !== 0;
        }),
    password: Yup.string()
        .max(255, 'O campo senha não pode possuir mais de 255 caracteres.')
        .required('O campo senha é de preenchimento obrigatório.'),
    state: Yup.string()
        .max(255, 'O campo UF não pode possuir mais de 255 caracteres.')
        .required('O campo UF é de preenchimento obrigatório.')
        .test('state', 'O campo UF é de preenchimento obrigatório.', val => {
            return +val !== 0;
        }),
    email: Yup.string()
        .max(255, 'O campo e-mail não pode possuir mais de 255 caracteres.')
        .email('O e-mail informado não é válido.')
        .trim()
        .required('O campo e-mail é de preenchimento obrigatório.'),
    phone: Yup.string()
        .max(255, 'O campo Telefone não pode possuir mais de 255 caracteres.')
        .trim()
        .required('O campo Telefone obrigatório.')
        .test('phone', 'O Telefone informado não é válido.', val => {
            const phoneUnformat = MaskUtils.unFormat(val);
            return phoneUnformat.length === 10 || phoneUnformat.length === 11;
        }),
});

export default schema;
