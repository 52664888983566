import styled from 'styled-components';
import {FaGoogle} from 'react-icons/fa';

export const Container = styled.div`
    box-sizing: border-box;
    padding: 0.3rem 2.5rem;
    background: #dd4c39;
    border-radius: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const IconGoogle = styled(FaGoogle)`
    color: #fff;
    font-size: 15px;
    margin-right: 10px;
`;

export const Label = styled.span`
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
`;
