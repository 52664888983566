import {Address} from "../../interfaces/Address";
import {State as StateInterface} from "../../interfaces/State";
import {City} from "../../interfaces/City";

export const ADDRESS_BY_CEP_REQUEST = 'ADDRESS_BY_CEP_REQUEST';
export const FIND_STATES_REQUEST = 'FIND_STATES_REQUEST';
export const FIND_STATES_SUCCESS = 'FIND_STATES_SUCCESS';
export const FIND_CITIES_BY_STATE_REQUEST = 'FIND_CITIES_BY_STATE_REQUEST';
export const FIND_CITIES_BY_STATE_SUCCESS = 'FIND_CITIES_BY_STATE_SUCCESS';
export const ADDRESS_BY_CEP_REQUEST_ERROR = 'ADDRESS_BY_CEP_REQUEST_ERROR';
export const ADDRESS_BY_CEP_SUCCESS = 'ADDRESS_BY_CEP_SUCCESS';
export const CLEAR_ADDRESS_BY_CEP = 'CLEAR_ADDRESS_BY_CEP';

export interface AddressByCepRequestAction {
    type: typeof ADDRESS_BY_CEP_REQUEST,
    payload: {
        cep: string
    }
}

export interface FindStatesRequestAction {
    type: typeof FIND_STATES_REQUEST,
}

export interface FindStatesSuccessAction {
    type: typeof FIND_STATES_SUCCESS,
    payload: {
        states: StateInterface[]
    }
}

export interface FindCitiesSuccessAction {
    type: typeof FIND_CITIES_BY_STATE_SUCCESS,
    payload: {
        cities: City[]
    }
}

export interface FindCitiesByStateRequestAction {
    type: typeof FIND_CITIES_BY_STATE_REQUEST,
    payload: {
        stateId: number
    }
}

export interface AddressByCepSuccessAction {
    type: typeof ADDRESS_BY_CEP_SUCCESS,
    payload: {
        address: Address
    }
}

export interface AddressRequestErrorAction {
    type: typeof ADDRESS_BY_CEP_REQUEST_ERROR
}

export interface ClearAddressByCepAction {
    type: typeof CLEAR_ADDRESS_BY_CEP
}

export const findStatesRequestAction = (): FindStatesRequestAction => ({
    type: FIND_STATES_REQUEST,
});

export const findCitiesByStateRequestAction = (stateId: number): FindCitiesByStateRequestAction => ({
    type: FIND_CITIES_BY_STATE_REQUEST,
    payload: {
        stateId
    }
});

export const findStatesSuccessAction = (states: StateInterface[]): FindStatesSuccessAction => ({
    type: FIND_STATES_SUCCESS,
    payload: {
        states
    }
});

export const findCitiesSuccessAction = (cities: City[]): FindCitiesSuccessAction => ({
    type: FIND_CITIES_BY_STATE_SUCCESS,
    payload: {
        cities
    }
});


export const addressByCepRequestAction = (cep: string): AddressByCepRequestAction => ({
    type: ADDRESS_BY_CEP_REQUEST,
    payload: {
        cep
    }
});

export const clearAddressByCepAction = (): ClearAddressByCepAction => ({
    type: CLEAR_ADDRESS_BY_CEP,
});

export const addressByCepSuccessAction = (address: Address): AddressByCepSuccessAction => ({
    type: ADDRESS_BY_CEP_SUCCESS,
    payload: {
        address
    }
});

export const addressRequestErrorAction = (): AddressRequestErrorAction => ({
    type: ADDRESS_BY_CEP_REQUEST_ERROR
});

export type Action =
    AddressByCepRequestAction
    | FindStatesRequestAction
    | FindStatesSuccessAction
    | FindCitiesSuccessAction
    | FindCitiesByStateRequestAction
    | AddressByCepSuccessAction
    | AddressRequestErrorAction
    | ClearAddressByCepAction;

export interface State {
    loading: boolean,
    address: Address | null,
    cep: string | null,
    stateId: number | null,
    states: StateInterface[],
    cities: City[]
}

const INITIAL_STATE = {
    loading: false,
    address: null,
    cep: null,
    stateId: null,
    states: [],
    cities: []
};

const addressReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ADDRESS_BY_CEP_SUCCESS: {
            const {address} = action.payload;
            return {
                ...state,
                loading: false,
                address
            };
        }
        case ADDRESS_BY_CEP_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FIND_STATES_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FIND_STATES_SUCCESS: {
            const {states} = action.payload;
            return {
                ...state,
                states,
                loading: false
            }
        }
        case FIND_CITIES_BY_STATE_SUCCESS: {
            const {cities} = action.payload;
            return {
                ...state,
                cities,
                loading: false
            }
        }
        case FIND_CITIES_BY_STATE_REQUEST: {
            const {stateId} = action.payload;
            return {
                ...state,
                stateId,
                loading: true
            }
        }
        case ADDRESS_BY_CEP_REQUEST_ERROR: {
            return {
                ...state,
                loading: false
            }
        }
        case CLEAR_ADDRESS_BY_CEP: {
            return {
                ...state,
                loading: false,
                address: null
            }
        }
        default:
            return state;
    }
};

export default addressReducer;
