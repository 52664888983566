import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
    center: () => `
        text-align: center;
`,
    right: () => `
        text-align: right;


`,
    left: () => `
        text-align: left;

`,
    justify: () => `
        text-align: justify;
        
`,
    small: () => `
        font-size: small;

`,
    medium: () => `
        font-size: medium;

`,
    large: () => `
        font-size: large;
        
`,
    xlarge: () => `
        font-size: x-large;
        
`,
    xxlarge: () => `
        font-size: xx-large;  
`,
    uppercase: () => `
        text-transform: uppercase;
`,
    bold: () => `
        font-weight: bolder;
    `
};

type Props = {
    modifiers?: string[] | string,
    color?: string
}

const Paragraph = styled.p<Props>`
    color: ${props => props.color? props.color : 'unset'};
    ${applyStyleModifiers(MODIFIER_CONFIG)}
`;
export default Paragraph;
