import React from 'react';
import {TestimonialContainer, TestimonialImageStyled} from "./styles";

const reactSlideshow = require('react-slideshow-image');

type Props = {
    testimonials: any[]
};

const TestimonialSlider = ({testimonials}: Props) => {
    const slideProperties = {
        autoplay: false,
        duration: 3000,
        transitionDuration: 500,
        infinite: true,
        arrows: true,
        indicators: false,
    };

    return (
        <div className="slide-container" style={{width: '90%'}}>
            <reactSlideshow.Slide {...slideProperties}>
                {
                    testimonials?.map( (t, i) =>
                       <div key={i} className="each-fade">
                           <TestimonialContainer>
                               {
                                   t?.image && <TestimonialImageStyled src={`data:image/jpeg;base64,${t?.image}`}/>
                               }
                               <h3>{t?.name}</h3>
                               <p>{t?.text}</p>
                           </TestimonialContainer>
                       </div>
                   )
                }
            </reactSlideshow.Slide>
        </div>
    );
};

export default TestimonialSlider;
