import styled from 'styled-components';
import {FaFacebookF} from 'react-icons/fa';

export const Container = styled.div`
    box-sizing: border-box;
    padding: 0.3rem 2.5rem;
    background: #3b4db3;
    border-radius: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
`;

export const IconFacebook = styled(FaFacebookF)`
    color: #fff;
    font-size: 15px;
    margin-right: 10px;
`;

export const Label = styled.span`
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
`;
