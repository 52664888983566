import styled from 'styled-components';
import {Input} from 'antd';

export const TextArea = styled(Input.TextArea)`
    border-radius: 25px;
    font-size: 1rem;
    margin: 0.5rem 0;
    text-align: center;
    resize: none;
`;
