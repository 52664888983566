import styled from "styled-components";
import Paragraph from "../Paragraph";

type Props = {
    color?: string;
    align?: string;
    middlewhite?: boolean;
    width?: string;
    centeredContent?: boolean;
    verticalCenter?: boolean;
    background?: any;
};

export const TextBlock = styled.div<Props>`
    background-image: url(${props => props.background});
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: ${props => props.width ? `${props.width}%` : '100%'};
    height: 100%;
    justify-content: ${props => props.verticalCenter ? 'center' : 'space-evenly'};
    top: 0;
    left: 0;   
    padding: 6% 0;
    @media (max-width: 1243px) {
        padding: 5% 0;
        p {
            font-size: xx-large;
        }
    }    
`;

export const AbsoluteParagraph = styled(Paragraph)<Props>`
    position: absolute;
    bottom: 0.5rem;
    right: 50%;
    z-index: 2;
    ${props => props.centeredContent ?
    `
        width: 100%;
        text-align: center;
        right: unset;
    ` : ' '}
    @media (max-width: 1243px) {
        width: 100%;
        right: unset;
        text-align: center;
    }
    @media (max-width: 1455px) {
        bottom: 0;
    }   
`;

export const BannerTitle = styled.h3<Props>`
    padding: 0 5%;
    font-size: 60px;
    text-align: left;
    text-align: ${props => props.align};
    line-height: 1.32;
    letter-spacing: 2px;
    font-family: OpenSans-Semibold, "Open Sans Semibold", sans-serif;
    font-weight: 600;
    font-style: normal;
    ${props => !props.middlewhite ? `color: ${props.color ? props.color : '#7e778c'}` : ``};
    ${props => props.middlewhite ?
    `
        color: white;
        text-shadow: 2px 2px #7e778c;
        span:first-child {
            color: ${props.color ? props.color : '#7e778c'};
            text-shadow: unset;

        }
        span:last-child {
            color: ${props.color ? props.color : '#7e778c'};
            text-shadow: unset;
        }
    ` : ' '}
    @media (max-width: 1150px) {
        font-size: large !important;
        font-weight: bolder;
        letter-spacing: unset;
        text-align: center;
    }  

`;

export const BannerText = styled.h4<Props>`
    padding: 0 5%;
    font-size: 30px;
    text-align: ${props => props.align};
    line-height: 0.82;
    letter-spacing: 4.5px;
    font-family: OpenSans, "Open Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: ${props => props.color ? props.color : '#7e778c'};
    @media (max-width: 1150px) {
        font-size: small !important;
        font-weight: bolder;
        letter-spacing: unset;
    }  
`;

export const ImageBanner = styled.img`
    filter: contrast(0.8);
    max-width: 100%;
    margin: 0 auto;
`;

export const ButtonFooter = styled.div`
    z-index: 1; 
    margin-top: 6%;
    @media (max-width: 1150px) {
        margin-top: 2%;
    } 
`;
