import {Lead} from '../../interfaces/Lead';
import {ChangePasswordRequest} from "../../interfaces/ChangePasswordRequest";

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_FACEBOOK_REQUEST = 'AUTH_FACEBOOK_REQUEST';
export const AUTH_GOOGLE_REQUEST = 'AUTH_GOOGLE_REQUEST';
export const COMPLETE_USER_DATA_REQUEST = 'COMPLETE_USER_DATA_REQUEST';
export const COMPLETE_USER_DATA_SUCCESS = 'COMPLETE_USER_DATA_SUCCESS';
export const COMPLETE_USER_DATA_ERROR = 'COMPLETE_USER_DATA_ERROR';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const SHOW_LOGIN = 'SHOW_LOGIN';
export const HIDE_LOGIN = 'HIDE_LOGIN';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_INCOMPLETE = 'AUTH_INCOMPLETE';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CLEAR_CHANGE_PASSWORD_STATE = 'CLEAR_CHANGE_PASSWORD_STATE';
export const CLEAR_FORGOT_PASSWORD_STATE = 'CLEAR_FORGOT_PASSWORD_STATE';
export const CANCEL_INCOMPLETE_AUTH = 'CANCEL_INCOMPLETE_AUTH';

export interface AuthRequestAction {
    type: typeof AUTH_REQUEST,
    payload: {
        username: string,
        password: string,
    }
}

export interface AuthFacebookRequestAction {
    type: typeof AUTH_FACEBOOK_REQUEST,
    payload: {
        access_token: string
    }
}

export interface CompleteUserDataRequestAction {
    type: typeof COMPLETE_USER_DATA_REQUEST,
    payload: {
        user: Lead
    }
}

export interface CompleteUserDataSuccessAction {
    type: typeof COMPLETE_USER_DATA_SUCCESS,
}

export interface CompleteUserDataErrorAction {
    type: typeof COMPLETE_USER_DATA_ERROR,
}

export interface AuthGoogleRequestAction {
    type: typeof AUTH_GOOGLE_REQUEST,
    payload: {
        access_token: string
    }
}

export interface ForgotPasswordRequestAction {
    type: typeof FORGOT_PASSWORD_REQUEST,
    payload: {
        username: string
    }
}

export interface ChangePasswordRequestAction {
    type: typeof CHANGE_PASSWORD_REQUEST,
    payload: {
        changePasswordRequest: ChangePasswordRequest
    }
}

export interface ChangePasswordSuccessAction {
    type: typeof CHANGE_PASSWORD_SUCCESS,
}

export interface ChangePasswordErrorAction {
    type: typeof CHANGE_PASSWORD_ERROR,
}

export interface ClearChangePasswordStateAction {
    type: typeof CLEAR_CHANGE_PASSWORD_STATE,
}

export interface ClearForgotPasswordStateAction {
    type: typeof CLEAR_FORGOT_PASSWORD_STATE,
}

export interface CancelIncompleteAuthAction {
    type: typeof CANCEL_INCOMPLETE_AUTH,
}

export interface ForgotPasswordSuccessAction {
    type: typeof FORGOT_PASSWORD_SUCCESS,
}

export interface ShowLoginAction {
    type: typeof SHOW_LOGIN,
}

export interface HideLoginAction {
    type: typeof HIDE_LOGIN,
}

export interface AuthSuccessAction {
    type: typeof AUTH_SUCCESS,
    payload: {
        token: string,
        user: Lead
    }
}

export interface AuthIncompleteAction {
    type: typeof AUTH_INCOMPLETE,
    payload: {
        token: string,
        user: Lead
    }
}

export interface AuthFailedAction {
    type: typeof AUTH_FAILED,
    payload: {
        error: any,
    }
}

export interface AuthLogoutAction {
    type: typeof AUTH_LOGOUT,
}

export const completeUserDataRequestAction = (
    user: Lead
): CompleteUserDataRequestAction => ({
    type: COMPLETE_USER_DATA_REQUEST,
    payload: {
        user
    }
});

export const completeUserDataSuccessAction = (): CompleteUserDataSuccessAction => ({
    type: COMPLETE_USER_DATA_SUCCESS,
});

export const completeUserDataErrorAction = (): CompleteUserDataErrorAction => ({
    type: COMPLETE_USER_DATA_ERROR,
});

export const authRequestAction = (
    username: string,
    password: string,
): AuthRequestAction => ({
    type: AUTH_REQUEST,
    payload: {
        username,
        password,
    }
});

export const authFacebookRequestAction = (
    access_token: string
): AuthFacebookRequestAction => ({
    type: AUTH_FACEBOOK_REQUEST,
    payload: {
        access_token
    }
});

export const authGoogleRequestAction = (
    access_token: string
): AuthGoogleRequestAction => ({
    type: AUTH_GOOGLE_REQUEST,
    payload: {
        access_token
    }
});

export const forgotPasswordRequestAction = (
    username: string,
): ForgotPasswordRequestAction => ({
    type: FORGOT_PASSWORD_REQUEST,
    payload: {
        username,
    }
});

export const changePasswordRequestAction = (
    changePasswordRequest: ChangePasswordRequest,
): ChangePasswordRequestAction => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload: {
        changePasswordRequest,
    }
});

export const changePasswordSuccessAction = (): ChangePasswordSuccessAction => ({
    type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordErrorAction = (): ChangePasswordErrorAction => ({
    type: CHANGE_PASSWORD_ERROR,
});

export const clearChangePasswordStateAction = (): ClearChangePasswordStateAction => ({
    type: CLEAR_CHANGE_PASSWORD_STATE,
});

export const clearForgotPasswordStateAction = (): ClearForgotPasswordStateAction => ({
    type: CLEAR_FORGOT_PASSWORD_STATE,
});

export const cancelIncompleteAuthAction = (): CancelIncompleteAuthAction => ({
    type: CANCEL_INCOMPLETE_AUTH,
});

export const forgotPasswordSuccessAction = (): ForgotPasswordSuccessAction => ({
    type: FORGOT_PASSWORD_SUCCESS,
});

export const showLoginAction = (): ShowLoginAction => ({
    type: SHOW_LOGIN,
});

export const hideLoginAction = (): HideLoginAction => ({
    type: HIDE_LOGIN,
});

export const authSuccessAction = (
    token: string,
    user: Lead
): AuthSuccessAction => ({
    type: AUTH_SUCCESS,
    payload: {
        token,
        user
    }
});

export const authIncompleteAction = (
    token: string,
    user: Lead
): AuthIncompleteAction => ({
    type: AUTH_INCOMPLETE,
    payload: {
        token,
        user
    }
});

export const authFailedAction = (
    error: any,
): AuthFailedAction => ({
    type: AUTH_FAILED,
    payload: {
        error,
    }
});

export const authLogoutAction = (): AuthLogoutAction => ({
    type: AUTH_LOGOUT,
});

export type Action =
    AuthRequestAction
    | CompleteUserDataRequestAction
    | CompleteUserDataSuccessAction
    | CompleteUserDataErrorAction
    | AuthSuccessAction
    | AuthIncompleteAction
    | AuthFailedAction
    | AuthLogoutAction
    | ShowLoginAction
    | HideLoginAction
    | ForgotPasswordRequestAction
    | ForgotPasswordSuccessAction
    | ChangePasswordRequestAction
    | ChangePasswordSuccessAction
    | ChangePasswordErrorAction
    | ClearChangePasswordStateAction
    | ClearForgotPasswordStateAction
    | AuthFacebookRequestAction
    | AuthGoogleRequestAction
    | CancelIncompleteAuthAction
    ;

export interface State {
    username: string,
    password: string,
    facebook_token: string,
    google_token: string,
    authenticated: boolean,
    authIncomplete: boolean,
    token: string | null,
    error: any | null,
    loading: boolean,
    user: Lead | null,
    loginPageVisible: boolean,
    forgotPasswordSuccess: boolean,
    changePasswordSuccess: boolean,
}

const INITIAL_STATE = {
    username: '',
    password: '',
    facebook_token: '',
    google_token: '',
    authenticated: false,
    authIncomplete: false,
    token: null,
    error: null,
    loading: false,
    user: null,
    loginPageVisible: false,
    forgotPasswordSuccess: false,
    changePasswordSuccess: false
};


const authenticationReducer = (state: State = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case SHOW_LOGIN:
            return {
                ...state,
                loginPageVisible: true
            };
        case HIDE_LOGIN:
            return {
                ...state,
                loginPageVisible: false
            };
        case AUTH_REQUEST:
            const {username, password} = action.payload;
            return {
                ...state,
                username,
                password,
                authenticated: false,
                loading: true
            };
        case COMPLETE_USER_DATA_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPLETE_USER_DATA_SUCCESS:
            return {
                ...state,
                authenticated: true,
                authIncomplete: false,
                loginPageVisible: false,
                loading: false
            };
        case COMPLETE_USER_DATA_ERROR:
            return {
                ...state,
                loading: false
            };
        case AUTH_FACEBOOK_REQUEST:
            const {access_token} = action.payload;
            return {
                ...state,
                facebook_token: access_token,
                authenticated: false,
                loading: true
            };
        case AUTH_GOOGLE_REQUEST: {
            const {access_token} = action.payload;
            return {
                ...state,
                google_token: access_token,
                authenticated: false,
                loading: true
            };
        }
        case AUTH_SUCCESS:
            const {token, user} = action.payload;
            return {
                ...state,
                token,
                user,
                authenticated: true,
                loading: false
            };
        case AUTH_INCOMPLETE:
            const {token: t, user: u} = action.payload;
            return {
                ...state,
                token: t,
                user: u,
                authIncomplete: true,
                loginPageVisible: false,
                loading: false
            };
        case CANCEL_INCOMPLETE_AUTH:
            return {
                ...state,
                user: null,
                token: null,
                authenticated: false,
                authIncomplete: false,
                loginPageVisible: false
            };
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordSuccess: true,
                loading: false
            };
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: true,
                loading: false
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false
            }
        case AUTH_FAILED:
            const {error} = action.payload;
            return {
                ...state,
                error,
                authenticated: false,
                token: null,
                user: null,
                loading: false
            };
        case AUTH_LOGOUT: {
            return {
                ...state,
                user: null,
                token: null,
                authenticated: false,
                loginPageVisible: false
            };
        }
        case CLEAR_CHANGE_PASSWORD_STATE: {
            return {
                ...state,
                changePasswordSuccess: false
            };
        }
        case CLEAR_FORGOT_PASSWORD_STATE: {
            return {
                ...state,
                forgotPasswordSuccess: false
            };
        }
        default:
            return state;
    }
};

export default authenticationReducer;
